import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Index.vue'),
    redirect: '/index',
    children:[
      {
        path: 'index',
        name: 'Index',
        component: () => import('../views/pages/Home.vue'),
      },
      {
        path: 'introduction',
        name: 'Introduction',
        component: () => import('../views/pages/Introduction.vue'),
      },
      {
        path: 'notes',
        name: 'Notes',
        component: () => import('../views/pages/Notes.vue'),
      },
      {
        path: 'rules',
        name: 'Rules',
        component: () => import('../views/pages/Rules.vue'),
      },
      {
        path: 'expert',
        name: 'Expert',
        component: () => import('../views/pages/Expert.vue'),
      },
      {
        path: 'publish',
        name: 'Publish',
        component: () => import('../views/pages/Publish.vue'),
      },
      {
        path: 'news',
        name: 'News',
        component: () => import('../views/pages/NewsIndex.vue'),
        redirect: '/news/list',
        children: [
          {
            path: 'list',
            name: 'NewsList',
            component: () => import('../views/pages/News.vue'),
          },
          {
            path: ':id',
            name: 'NewsDetail',
            component: () => import('../views/pages/NewsDetail.vue'),
          }
        ]
      },
      {
        path: 'review',
        name: 'Review',
        component: () => import('../views/pages/Review.vue'),
      }
    ]
  },
  {
    path: '/user',
    name: 'User',
    component: () => import('../views/user/Index.vue'),
    redirect: '/user/login',
    children:[
      {
        path: 'login',
        name: 'Login',
        component: () => import('../views/user/Login.vue'),
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('../views/user/Register.vue'),
      },
      {
        path: 'reset_password',
        name: 'ResetPwd',
        component: () => import('../views/user/ResetPwd.vue'),
      }
    ]
  },
  {
    path: '/declare',
    name: 'Declare',
    component: () => import('../views/declare/Index.vue'),
    redirect: '/declare/basic',
    children:[
      {
        path: 'basic',
        name: 'Basic',
        component: () => import('../views/declare/Basic.vue'),
      },
      {
        path: 'project',
        name: 'Project',
        component: () => import('../views/declare/Project.vue'),
      }
    ]
  },
]

const router = new VueRouter({
  routes
})

export default router
