  /*
   * Copyright(c) 2021 architectural award, All Rights Reserved.
   * Author: lxj
   */
  import fetch from '@/utils/fetch';

  export default {
    // 获取新闻列表
    getNews: params => {
      return fetch({
        url: 'inquiry/news',
        method: 'get',
        params
      })
    },
    // 获取新闻信息
    getNewsInfo: id => {
      return fetch({
        url: `inquiry/news/${id}`,
        method: 'get'
      })
    }
  }