<template>
  <div class="review">
    <div class="bannerWrap">
      <img class="banner" src="/img/banner.jpg" alt="">
    </div>
    <div class="section">
      <Row>
        <Col :lg="18" :xs="24" class="leftInfo">
          <div class="info">
            <p class="mobile title center">往届回顾</p>
            <Table stripe :columns="columns" :data="list"></Table>
          </div>
        </Col>
        <Col span="6" class="pc">
          <img class="time" src="/img/introduction/time.png" alt="">
          <div id="contact" class="contact">
            <p class="title">联系我们</p> 
            <div class="clearfix">
              <div class="label">评审组委会：</div>
              <div class="info">010-88083947/2319</div>  
            </div>
            <div class="clearfix">
              <div class="label">组委会邮箱：</div>
              <div class="info">cbmea2006@126.com</div>  
            </div>
            <div class="clearfix">
              <div class="label">组委会地址：</div>
              <div class="info">北京市海淀区三里河路甲11号 中国建材大厦C座3层</div>  
            </div>
            <div class="clearfix">
              <div class="label">联&nbsp;&nbsp;&nbsp;系&nbsp;&nbsp;&nbsp;人：</div>
              <div class="info">13901127933(刘秀明)</div>  
            </div>
            <div class="clearfix">
              <div class="label">微信公众号：</div>
              <div class="info">国家建筑应用创新大奖</div>  
            </div>
            <p class="center"><img class="wxCode" src="/img/wxCode.jpg" alt=""></p>
          </div>
        </Col>
      </Row>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      list: [],
      columns: [
        {
          title: '企业名称',
          key: 'applicantName'
        },
        {
            title: '获奖产品',
            key: 'name'
        },
        {
            title: '应用项目',
            key: 'projectName'
        }
      ]
    }
  },
  mounted(){
    this.list = [
      {id: 1, applicantName: '旭硝子化工贸易（上海）有限公司', name: '【Fluon® ETFE】（中文：氟朗）牌ETFE 薄膜', projectName: '国家游泳馆'},
      {id: 2, applicantName: '环球石材（东莞）有限公司', name: '霞红雕刻外墙', projectName: '哈药集团制药六厂科研开发中心大楼'},
      {id: 3, applicantName: '福建溪石股份有限公司', name: 'G602', projectName: '武汉汉口江滩防洪及环境综合整治工程'},
      {id: 4, applicantName: '山东石通石业有限公司', name: 'JST加强肋保温装饰复合板', projectName: '威海自由东方国际大厦'},
      {id: 5, applicantName: '北京宝贵石艺科技有限公司', name: '装饰混凝土轻型挂板', projectName: '西安大唐西市博物馆'},
      {id: 6, applicantName: '南京倍立达（集团）公司', name: 'GRC装饰艺术材料', projectName: '澳门威尼斯人酒店'},
      {id: 7, applicantName: '嘉德保特佳（北京）科技有限公司', name: '保特佳™混凝土拉色（Protect Guard® Color/PGC）', projectName: '鄂尔多斯民族大剧院'},
      {id: 8, applicantName: '佛山市南海新元素板业有限公司', name: '无石棉硅酸钙板——波特防火板', projectName: '天津站交通枢纽工程'},
      {id: 9, applicantName: '北京绿华园科技有限公司', name: '轻质复合板组合房屋', projectName: '北京平谷区新农村建设项目'},
      {id: 10, applicantName: '上海墙特节能材料有限公司', name: '“墙特BESTONE®”魔方砖', projectName: '长春证大光明城'},
      {id: 11, applicantName: '天津国环页岩制品有限公司', name: '烧结页岩非承重保温砖', projectName: '北京中关村电子信息基地硅谷亮城'},
      {id: 12, applicantName: '大连天座建筑艺术工程有限公司', name: '钢拱壳穹顶', projectName: '北京吉利大学图书馆穹顶'},
      {id: 13, applicantName: '北新集团建材股份有限公司', name: '龙牌石膏板', projectName: '北京大学图书馆'},
      {id: 14, applicantName: '东莞华尔泰装饰材料有限公司', name: '铝塑复合板', projectName: '东莞长安国际酒店'},
    ]
  }
}
</script>

<style lang="scss" scoped>
.review{
  .section{
    width: 80%;
    margin: auto;
    color: #8C8C8C;
    line-height: 1.8em;
    .leftInfo{
      border-right: 1px solid #E9E9E9;
      .info{
        padding: 50px 15px;
        
      }
    }
    .time{
      width: 100%;
      padding-top: 50px;
    }
    .contact{
      padding: 20px;
      line-height: 1.8em;
      .title{
        font-size: 18px;
        padding-bottom: 10px;
      }
    }
  }
  @media screen and (max-width: 992px) {
    .section{
      width: 100%;
      .leftInfo{
        border-right: none;
        .info{
          padding-bottom: 0;
          .title{
            font-size: 24px;
            padding-bottom: 20px;
            font-weight: bold;
            color: #4D4D4D;
          }
        }
      }
    }
  }
}
</style>