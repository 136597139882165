<template>
  <div>
    <div class="list" v-show="show=='basic'">
      <Row :gutter="30">
        <Col span="12">
          <Form>
            <FormItem label="参评单位名称：" :label-width="110">
              <Input v-model="searchName" placeholder="请输入参评单位名称/个人姓名" clearable @on-enter="search"></Input>
            </FormItem>
          </Form>
        </Col>
        <Col span="6"><Button class="defaultBtn" @click="search">查询</Button></Col>
        <Col span="6" align="right"><Button class="defaultBtn" @click="changeShows('form')">新增参评单位</Button></Col>
      </Row>
      <Table :data="list" :columns="columns" :loading="loading" stripe></Table>
      <div class="pageWrap">
        <Page :total="total" :page-size="30" :current="currentPage" show-total @on-change="changePage"></Page>
      </div>
    </div>
    <Form v-show="show=='form'" ref="form" :model="info" :rules="rules" :label-width="110">
      <Row :gutter="15">
        <Col span="12">
          <FormItem label="参评单位类型" prop="applicantType">
            <Select v-model="info.applicantType" @on-change="setCertType">
              <Option value="个人">个人</Option>
              <Option value="院校或科研机构">院校或科研机构</Option>
              <Option value="企业">企业</Option>
            </Select>
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem :label="nameLabel" prop="name">
            <Input v-model="info.name"></Input>
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem label="联系地址" prop="address">
            <Input v-model="info.address"></Input>
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem label="邮政编码" prop="postcode">
            <Input v-model="info.postcode" maxlength="6"></Input>
          </FormItem>
        </Col>
      </Row>
      <FormItem label="参评单位简介" prop="description">
        <Input v-model="info.description" type="textarea" :autosize="{minRows:3}" maxlength="1000" show-word-limit></Input>
      </FormItem>
      <Row :gutter="15">
        <Col span="12">
          <FormItem label="参评证明" prop="certType">
            <Input v-model="info.certType" readonly placeholder="内容根据“参评单位类型”自动填充"></Input>
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem label="" prop="certificateFiles" :label-width="0">
            <Upload
              ref="certificateFiles"
              :action="imgApi"
              :headers="{Authorization: token}"
              name="pictures"
              :data="{applyType: 'CERTIFICATE'}"
              :show-upload-list="false"
              :on-success="handleSuccess"
              :format="['jpg','jpeg','pdf']"
              :max-size="20480"
              :on-format-error="handleFormatError"
              :on-exceeded-size="handleMaxSize"
              :on-progress="handleProgress"
              multiple
              accept="image/jpg, image/jpeg, application/pdf">
              <Button class="defaultBtn">选择文件</Button>
            </Upload>
            <span class="note">文件格式：“.JPG”或“.PDF”；文件大小：不大于20M</span>
          </FormItem>
        </Col>
      </Row>
      <div class="imgWrap" v-if="fileList.length>0">
        <div class="imgItem" v-for="(item, i) in fileList" :key="i">
          <template v-if="item.status == 'finished' || item.status==null">
            <p v-if="item.fileType=='FILE'" class="fileName threeRows">{{item.name}}</p>
            <img v-else-if="item.size<20480" class="img" :src="getImageUrl(item.path, 'square-small')" alt="">
            <img v-else class="img" :src="getImageUrl(item.path)" alt="">
            <div class="mask"><Icon type="md-trash" class="delBtn" @click="removeFile(i)" /></div>
          </template>
          <template v-else>
            <Progress v-if="item.showProgress" :percent="item.percentage" hide-info></Progress>
          </template>
        </div>
      </div>
      <Row :gutter="15">
        <Col span="12">
          <FormItem label="联系人" prop="contactName">
            <Input v-model="info.contactName"></Input>
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="15">
        <Col span="12">
          <FormItem label="职务" prop="contactJob">
            <Input v-model="info.contactJob"></Input>
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="15">
        <Col span="12">
          <FormItem label="联系电话" prop="phone">
            <Input v-model="info.phone"></Input>
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="15">
        <Col span="12">
          <FormItem label="电子邮箱" prop="email">
            <Input v-model="info.email"></Input>
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="15">
        <Col span="12">
          <FormItem label="推荐单位" prop="pushCode">
            <Select v-model="info.pushCode">
              <Option value="有">有</Option>
              <Option value="无">无</Option>
            </Select>
          </FormItem>
        </Col>
        <!-- <Col span="12">
          <FormItem label="" :label-width="0" prop="pushCompany">
            <Input v-model="info.pushCompany" :disabled="info.pushCode=='无'" placeholder="请填写推荐单位名称，如：某某协会/学会/科技局"></Input>
          </FormItem>
        </Col> -->
        <Col span="12" v-if="info.pushCode=='有'">
          <FormItem label="" prop="pushCompany" :label-width="0">
            <Select v-model="info.pushCompany">
              <Option :value="p.value" v-for="p in pushCompany" :key='p.id'>{{p.label}}</Option>
            </Select>
          </FormItem>
        </Col>
      </Row>
      <p class="center"><Button class="defaultBtn" :loading="submitting" @click="submit">保存，去提交参评项目信息</Button></p>
      <p class="center mgTop"><Button class="colorGreen" type="text" @click="changeShows('basic')">{{info.id?'取消保存':'返回单位列表'}}</Button></p>
    </Form>
  </div>
</template>

<script>
import userService from "@/api/user";
import { baseUrl } from "@/config/env";
import pushCompany from "@/assets/data/pushCompany";
export default {
  data() {
    return {
      show: "basic",
      list: [],
      total: 0,
      currentPage: 1,
      searchName: null,
      pushCompany,
      columns: [
        {
          title: "序号",
          key: "id",
          align: "center"
        },
        {
          title: "单位名称/个人姓名",
          key: "name",
          align: "center"
        },
        {
          title: "项目数量",
          key: "projectCount",
          align: "center"
        },
        {
          title: "审核状态",
          key: "status",
          align: "center",
          render: (h) => {
            // let status = params.row.status,
            //   statusText;
              // className;
            // if (status == "CHECKING") {
            //   statusText = "待审核";
            // } else if (status == "SUCCESS") {
            //   statusText = "已通过";
            // } else if (status == "FAILED") {
            //   statusText = "未通过";
            //   className = "colorRed";
            // }
            // return h("div", { class: className }, statusText);
            return h("div", "待审核");
          }
        },
        {
          title: "操作",
          key: "action",
          align: "center",
          render: (h, params) => {
            let btn1 = h(
                "Button",
                {
                  props: {
                    type: "text",
                    size: "small"
                  },
                  class: "textBtn colorGreen",
                  on: {
                    click: () => {
                      this.edit(params.row);
                    }
                  }
                },
                "编辑"
              ),
              btn2 = h(
                "Button",
                {
                  props: {
                    type: "text",
                    size: "small"
                  },
                  class: "textBtn colorRed",
                  on: {
                    click: () => {
                      this.delConfirm(params.index);
                    }
                  }
                },
                "删除"
              );
            return h("div", [btn1, btn2]);
          }
        }
      ],
      loading: false,

      imgApi: `${baseUrl}pictures`,
      token: this.$store.getters.token,
      submitting: false,
      nameLabel: "参评单位名称",
      info: { certificateFiles: [] },
      fileList: [],
      rules: {
        applicantType: [
          {
            required: true,
            message: "请选择参评单位类型",
            trigger: "blur,change"
          }
        ],
        name: [
          { required: true, message: "请输入名称", trigger: "blur,change" },
          {
            required: true,
            message: "名称60字以内",
            trigger: "blur,change",
            max: 60
          }
        ],
        address: [
          { required: true, message: "请输入联系地址", trigger: "blur,change" },
          {
            required: true,
            message: "联系地址120字以内",
            trigger: "blur,change",
            max: 120
          }
        ],
        postcode: [
          { required: true, message: "请输入邮政编码", trigger: "blur,change" }
        ],
        description: [
          {
            required: true,
            message: "请输入参评单位简介",
            trigger: "blur,change"
          }
        ],
        certType: [
          {
            required: true,
            message: "选择“参评单位类型”后自动填充",
            trigger: "blur,change"
          }
        ],
        certificateFiles: [
          {
            required: true,
            message: "请上传参评证明",
            validator: () => {
              if (this.info.certificateFiles.length == 0) {
                return false;
              }
              return true;
            }
          }
        ],
        contactName: [
          { required: true, message: "请输入联系人", trigger: "blur,change" },
          {
            required: true,
            message: "联系人60字以内",
            trigger: "blur,change",
            max: 60
          }
        ],
        contactJob: [
          {
            required: true,
            message: "请输入联系人职务",
            trigger: "blur,change"
          },
          {
            required: true,
            message: "联系人职务30字以内",
            trigger: "blur,change",
            max: 30
          }
        ],
        phone: [
          {
            required: true,
            pattern: /^1[3456789]\d{9}$|(^\d{3}-\d{7,8}|\d{4}-\d{7,8}$)/,
            message: "请输入正确的固定电话或移动电话",
            trigger: "blur,change"
          }
        ],
        email: [
          {
            required: true,
            message: "请输入正确的电子邮箱",
            pattern: /[\w]+(\.[\w]+)*@[\w]+(\.[\w])+/,
            trigger: "blur,change"
          },
          {
            required: true,
            message: "电子邮箱120字以内",
            trigger: "blur,change",
            max: 120
          }
        ],
        pushCode: [
          {
            required: true,
            message: "请选择有无推荐单位",
            trigger: "blur,change"
          }
        ],
        pushCompany: [
          {
            required: true,
            message: "请输入推荐单位",
            validator: (rule, value) => {
              if (this.info.pushCode == "有" && !value) {
                return false;
              }
              return true;
            },
            trigger: "blur,change"
          }
        ]
      },
      // timer: null,
      deadline: {}
    };
  },
  mounted() {
    if (this.gIsLogin()) {
      // 获取用户信息
      this.getCompetitionRegistration();
      // this.autoSubmit()
      // setTimeout(this.setInit, 500);
      this.getEndDate()
    } else {
      this.$router.push(`/user/login?fromUrl=${this.$route.fullPath}`);
    }
  },
  methods: {
    // 获取时间
    getEndDate(){
      userService.getEndDate(0)
        .then(res => {
          if (res.errorNo == 0) {
            // 提交成功执行
            this.deadline = res.data || {}
          } else {
            this.$Message.error(res.errorMessage);
          }
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
        });
    },
    // setInit() {},
    // 删除项目
    delConfirm(i) {
      let deadTime = new Date(this.deadline.editEndTime), now = new Date()
      if(deadTime<now){
        this.$Modal.info({
          title: '提示',
          content: '报名已经截至，不能删除单位信息，如有问题，请联系组委会。'
        })
        return
      }

      let info = this.list[i],
        _this = this;
      this.$Modal.confirm({
        title: "提示",
        content: `此参评单位有${
          info.projectCount
        }个关联项目，删除参评单位后关联项目也会删除，且不能恢复，您确定要删除参评单位“${
          info.name
        }”吗？`,
        okText: "删除",
        onOk: function() {
          _this.delBasic(i, info.id);
        }
      });
    },
    // 删除项目
    delBasic(i, id) {
      userService
        .deleteOneRegistration(id)
        .then(res => {
          if (res.errorNo == 0) {
            this.$Message.success("删除参评单位成功");
            this.list.splice(i, 1);
          } else {
            this.$Message.error(res.errorMessage);
          }
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
        });
    },
    // 初始化upload
    defaultImgList() {
      this.$refs.certificateFiles.fileList = this.fileList;
    },
    // 切换显示项目还是表单
    changeShows(v) {
      if (v == this.show) {
        return;
      }
      let deadTime = new Date(this.deadline.addEndTime), now = new Date()
      if(v == "form" && deadTime<now){
        this.$Modal.info({
          title: '提示',
          content: '报名已经截至，不能再次提交单位信息，如有问题，请联系组委会。'
        })
        return
      }
      this.show = v;
      if (v == "form") {
        this.info = {
          certificateFiles: []
        };
        this.fileList = [];
        this.defaultImgList();
        this.$refs.form.resetFields();
        // this.autoSubmit();
      } else {
        // this.timer && this.autoSubmit();
      }
    },
    // 根据名称查询单位
    search() {
      this.currentPage = 1;
      this.getCompetitionRegistration();
    },
    // 修改参评单位信息
    edit(info) {
      let deadTime = new Date(this.deadline.editEndTime), now = new Date()
      if(deadTime<now){
        this.$Modal.info({
          title: '提示',
          content: '报名已经截至，不能再次修改单位信息，如有问题，请联系组委会。'
        })
        return
      }
      this.fileList = [];
      this.info = Object.assign({}, info);
      for (let i = 0; i < this.info.certificateFiles.length; i++) {
        this.info.certificateFiles[i].status = "finished";
        this.fileList.push(this.info.certificateFiles[i]);
      }
      this.show = "form";
      this.setCertType(this.info.applicantType);
      this.defaultImgList();
      // this.autoSubmit();
    },
    changePage(p) {
      this.currentPage = p;
      this.getCompetitionRegistration();
      document.getElementsByClassName("list")[0].scrollTop = 0;
    },
    // // 设置自动保存
    // autoSubmit() {
    //   if (this.timer) {
    //     clearInterval(this.timer);
    //     this.timer = null;
    //   } else {
    //     let _this = this;
    //     this.timer = setInterval(function() {
    //       let temp = JSON.parse(JSON.stringify(_this.info));
    //       for (let i = 0; i < temp.certificateFiles.length; i++) {
    //         temp.certificateFiles[i].status = null;
    //       }
    //       userService
    //         .postCompetitionRegistration(temp)
    //         .then(res => {
    //           if (res.errorNo == 0) {
    //             if (!temp.id) {
    //               _this.info.id = res.data;
    //             }
    //           }
    //         })
    //         .catch(() => {});
    //     }, 300000);
    //   }
    // },
    // 获取报名信息
    getCompetitionRegistration() {
      this.loading = true;
      let userId = this.$store.getters.userInfo.id;
      userService
        .getCompetitionRegistration({
          "userId.eq": userId,
          sort: "id,desc",
          page: this.currentPage - 1,
          size: 30,
          "name.contains": this.searchName || null
        })
        .then(res => {
          if (res.errorNo == 0) {
            if (res.data.length > 0) {
              this.list = res.data;
              this.currentPage == 1 && (this.total = res.totalSize);
            } else {
              if (this.searchName) {
                this.list = [];
                this.total = 0;
              } else {
                let deadTime = new Date(this.deadline.addEndTime), now = new Date()
                if(deadTime<now){
                  this.list = res.data;
                  this.currentPage == 1 && (this.total = res.totalSize);
                }else{
                  this.show = "form";
                  this.info = { certificateFiles: [] };
                  this.defaultImgList();
                  // this.autoSubmit();  
                }
              }
            }
          } else {
            this.$Message.error(res.errorMessage);
          }
          this.loading = false;
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
          this.loading = false;
        });
    },
    // 根据参评单位类型赋值证明类型
    setCertType(val) {
      if (val == "个人") {
        this.info.certType = "上传个人身份证件";
        this.nameLabel = "参评个人姓名";
      } else if (val == "院校或科研机构") {
        this.info.certType = "上传院校或科研机构盖章的《参评申报表》";
        this.nameLabel = "参评单位名称";
      } else if (val == "企业") {
        this.info.certType = "上传营业执照复印件或企业盖章的《参评申报表》";
        this.nameLabel = "参评单位名称";
      } else {
        this.info.certType = null;
        this.nameLabel = "参评单位名称";
      }
    },
    // 提交表单
    submit() {
      let now = new Date()
      if(this.info.id){
        let editTime = new Date(this.deadline.editEndTime)
        if(editTime<now){
          this.$Modal.info({
            title: '提示',
            content: '报名已经截至，不能再次修改单位信息，如有问题，请联系组委会。'
          })
          return
        } 
      }else{
        let addTime = new Date(this.deadline.addEndTime)
        if(addTime<now){
          this.$Modal.info({
            title: '提示',
            content: '报名已经截至，不能再次提交单位信息，如有问题，请联系组委会。'
          })
          return
        }  
      }
      
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitting = true;
          for (let i = 0; i < this.info.certificateFiles.length; i++) {
            this.info.certificateFiles[i].status = null;
          }
          if (this.info.pushCode == "无" && this.info.pushCompany) {
            this.info.pushCompany = null;
          }
          this.info.status = 'CHECKING'
          userService
            .postCompetitionRegistration(this.info)
            .then(res => {
              if (res.errorNo == 0) {
                this.$Message.success("参评单位基本信息提交成功！");
                this.$router.push("/declare/project");
              } else {
                this.$Message.error(res.errorMessage);
              }
              this.submitting = false;
            })
            .catch(() => {
              this.submitting = false;
              this.$Message.error("连接服务器失败，请稍后再试");
            });
        } else {
          this.$Message.error("输入信息有误，请检查必填项是否全部填写");
        }
      });
    },
    handleProgress(event, file, fileList) {
      this.fileList = fileList;
      // 调用监听 上传进度 的事件
      event.target.onprogress = event => {
        let uploadPercent = parseFloat(
          (event.loaded / event.total * 100).toFixed(2)
        ); // 保留两位小数，具体根据自己需求做更改
        // 手动设置显示上传进度条 以及上传百分比
        file.showProgress = true;
        file.percentage = uploadPercent;
      };
    },
    handleSuccess(res, file) {
      if (res.errorNo) {
        this.$Message.error(res.errorMessage);
      } else {
        let data = res.data[0];
        if (data.path) {
          file.path = data.path;
          file.name = data.name;
          file.fileType = data.fileType;
          file.size = data.size;
          this.info.certificateFiles.push(data);
        }
        this.$forceUpdate();
      }
    },
    handleFormatError(file) {
      this.$Message.error({
        content: `文件${file.name}格式不正确，请上传jpg,jpeg格式的图片。`,
        duration: 5
      });
    },
    handleMaxSize(file) {
      this.$Message.error({
        content: `文件${file.name}太大，上传图片大小不能超过20M。`,
        duration: 5
      });
    },
    removeFile(i) {
      this.info.certificateFiles.splice(i, 1);
      this.fileList.splice(i, 1);
    }
  },
  // destroyed() {
  //   this.timer && this.autoSubmit();
  //   this.timer = null;
  // }
};
</script>

<style lang="scss" scoped>
.hidden {
  display: none;
}
.note {
  color: #b4b4b4;
  font-size: 12px;
  display: inline-block;
  padding-left: 5px;
}
.mgTop {
  margin-top: 10px;
}
.imgWrap {
  padding-bottom: 15px;
  padding-left: 110px;
  .imgItem {
    position: relative;
    top: 0;
    width: 80px;
    height: 80px;
    display: inline-block;
    margin: 0 10px 5px 0;
    background: rgba($color: #000000, $alpha: 0.1);
    vertical-align: top;
    .fileName {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
      padding: 0 5px;
      text-align: center;
      font-size: 12px;
      line-height: 1.3em;
    }
    .img,
    .mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .mask {
      display: none;
      background: rgba($color: #000000, $alpha: 0.5);
      text-align: center;
      .delBtn {
        color: #fff;
        font-size: 20px;
        margin-top: 30px;
        cursor: pointer;
      }
    }
  }
  .imgItem:hover .mask {
    display: inline-block;
  }
}
</style>