<template>
  <div>
    <div v-show="show=='project'" class="project">
      <Row :gutter="30">
        <Col span="12">
          <Form>
            <FormItem label="参评项目名称：" :label-width="110">
              <Input v-model="searchName" placeholder="请输入参评项目名称" clearable @on-enter="search"></Input>
            </FormItem>
          </Form>
        </Col>
        <Col span="12"><Button class="defaultBtn" @click="search">查询</Button></Col>
      </Row>
      <Row :gutter="30">
        <Col span="12">
          <div class="projectItem addItem">
            <div class="addBtnWrap">
              <p class="colorGreen">
                <span @click="addProject" class="addBtn"><Icon class="addIcon" type="ios-add-circle-outline" />新增参评项目</span>
              </p>
              <!-- <p>同一参评单位，申报同类作品不得超过2个</p> -->
            </div>
            <div class="projectImgWrap"></div>
            <p class="center projectTitle">项目名称</p>
            <p class="center">项目类型</p>
            <Row class="btnWrap">
              <Col span="12"><span class="myBtn">编辑</span></Col>
              <Col span="12"><span class="myBtn">删除</span></Col>
            </Row>
            <!-- <p class="center colorGreen">
              <span class="editBtn">编辑项目信息</span>
            </p> -->
          </div>
        </Col>
        <Col span="12" v-for="(p, i) in list" :key="i">
          <div class="projectItem">
            <div class="projectImgWrap">
              <img v-if="p.cover" class="projectImg" :src="getImageUrl(p.cover)" @load="gCenterImg($event, 1)" alt="">
              <img v-else class="projectImg" src="/img/1.png" @load="gCenterImg($event, 1)" alt="">
              <div class="statusWrap">
                <span class="statusBg" ></span><!-- :class="{succBg: p.checkStatus=='SUCCESS'}", failBg: p.checkStatus=='FAILED' -->
                <span class="statusText">{{getStatusText(p.checkStatus)}}</span>
              </div>
            </div>
            <p class="center projectTitle">{{p.name}}</p>
            <p class="center">{{p.type||'-'}}<template v-if="p.subType"> - {{p.subType}}</template></p>
            <Row class="btnWrap">
              <Col span="12" class="center bdRight"><span class="myBtn colorGreen" @click="editProject(p)">编辑</span></Col>
              <Col span="12" class="center"><span class="myBtn colorRed" @click="delProjectConfirm(i)">删除</span></Col>
            </Row>
          </div>
        </Col>
      </Row>
      <div class="pageWrap">
        <Page :total="total" :page-size="19" :current="currentPage" show-total @on-change="changePage"></Page>
      </div>
    </div>
    <Form v-show="show=='form'" ref="form" :model="info" :rules="rules" :label-width="125">
      <Row :gutter="15">
        <Col span="12">
          <FormItem label="项目名称" prop="name">
            <Input v-model="info.name" placeholder="项目工程名称+(具体应用的产品)"></Input>
          </FormItem>
        </Col>
        <Col span="12">
          <span class="note nameNote">或者其它可以表达出工程/项目和应用产品的命名方式</span>
        </Col>
      </Row>
      <Row :gutter="15">
        <Col span="12">
          <FormItem label="参评类别" prop="type">
            <Select v-model="info.type">
              <Option value="建筑应用创新综合类">建筑应用创新综合类</Option>
              <Option value="建筑应用创新单项类">建筑应用创新单项类</Option>
            </Select>
          </FormItem>
        </Col>
        <Col span="12" v-if="info.type=='建筑应用创新单项类'">
          <FormItem label="" prop="subType" :label-width="0">
            <Select v-model="info.subType">
              <Option value="建筑材料应用创新类">建筑材料应用创新类</Option>
              <Option value="装配式建筑应用创新类">装配式建筑应用创新类</Option>
              <Option value="建筑部品部件应用创新类">建筑部品部件应用创新类</Option>
              <Option value="设备设施应用创新类">设备设施应用创新类</Option>
              <Option value="其它应用创新类">其它应用创新类</Option>
            </Select>
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="15">
        <Col span="12">
          <FormItem label="所属参评单位" prop="regId">
            <Select v-model="info.regId">
              <Option v-for="v in companyList" :key="v.id" :value="v.id">{{v.name}}</Option>
            </Select>
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="15">
        <Col span="12">
          <FormItem label="完成时间" prop="completionDate">
            <DatePicker :clearable="false" :editable="false" format="yyyy-MM" v-model="info.completionDate" type="month" @on-change="setDate"/>
          </FormItem>
        </Col>
      </Row>
      <FormItem label="竣工验收文件" prop="acceptanceFile">
        <p>
          <Upload
            ref="imgList12"
            :action="imgApi0"
            :headers="{Authorization: token}"
            name="pictures"
            :data="{applyType: 'ACCEPTANCE_FILE'}"
            :show-upload-list="false"
            :on-success="handleSuccess12"
            :format="['jpg','jpeg','pdf']"
            :max-size="20480"
            :on-format-error="handleFormatError"
            :on-exceeded-size="handleMaxSize2"
            multiple
            :on-error="handleError"
            :on-progress="handleProgress12"
            accept="image/jpg, image/jpeg, application/pdf">
            <Button class="defaultBtn">上传扫描件</Button>
          </Upload>
          <span class="note">文件格式：“.JPG”或“.PDF”，文件大小：不大于20M</span>
        </p>
        <div class="imgWrap" v-if="imgList12.length>0">
          <div class="imgItem" v-for="(item, i) in imgList12" :key="item.id">
            <template v-if="item.status === 'finished'">
              <p v-if="item.fileType=='FILE'" class="fileName threeRows">{{item.name}}</p>
              <img v-else-if="item.size<20480" class="img" :src="getImageUrl(item.path, 'square-small')" alt="">
              <img v-else class="img" :src="getImageUrl(item.path)" alt="">
              <div class="mask"><Icon type="md-trash" class="delBtn" @click="removeImg12(i)" /></div>
            </template>
            <template v-else>
              <Progress v-if="item.showProgress" :percent="item.percentage" hide-info></Progress>
            </template>
          </div>  
        </div>
      </FormItem>
      <Row :gutter="15">
        <Col span="12">
          <FormItem label="项目所在地" prop="address">
            <Input v-model="info.address"></Input>
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="15">
        <Col span="12">
          <FormItem label="项目规模" prop="scale">
            <Input v-model="info.scale"></Input>
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="15">
        <Col span="12">
          <FormItem label="功能类型" prop="functionType">
            <Input v-model="info.functionType"></Input>
          </FormItem>
        </Col>
      </Row>
      <FormItem label="项目综述" prop="description">
        <Input v-model="info.description" type="textarea" placeholder="完成时间、地点、规模、功能" :autosize="{minRows:3}" maxlength="10000" show-word-limit></Input>
        <Upload
          ref="imgList13"
          :action="imgApi0"
          :headers="{Authorization: token}"
          name="pictures"
          :data="{applyType: 'DESCRIPTION_FILE'}"
          :show-upload-list="false"
          :on-success="handleSuccess13"
          :format="['jpg','jpeg']"
          :max-size="10240"
          :on-format-error="handleFormatError"
          :on-exceeded-size="handleMaxSize"
          multiple
          :on-error="handleError"
          :on-progress="handleProgress13"
          accept="image/jpg, image/jpeg">
          <Button class="defaultBtn">上传图片</Button>
        </Upload>
        <span class="note mgTop">非必填，若文字描述不清楚，可上传图片作为辅助说明，图片大小不能超过10MB</span>
        <div class="imgWrap" v-if="imgList13.length>0">
          <div class="imgItem" v-for="(item, i) in imgList13" :key="item.id">
            <template v-if="item.status === 'finished'">
              <img v-if="item.size<20480" class="img" :src="getImageUrl(item.path, 'square-small')" alt="">
              <img v-else class="img" :src="getImageUrl(item.path)" alt="">
              <div class="mask"><Icon type="md-trash" class="delBtn" @click="removeImg13(i)" /></div>
            </template>
            <template v-else>
              <Progress v-if="item.showProgress" :percent="item.percentage" hide-info></Progress>
            </template>
          </div>  
        </div>
      </FormItem>
      <FormItem label="创新性" prop="creativity">
        <Input v-model="info.creativity" type="textarea" placeholder="阐述项目设计理念、目的、实施过程简述和应用成果；创新点对技术进步的贡献；技术推广前景等。建筑应用创新单项类需着重描述创新的着力点，例如在材料、设计、技术、工艺等方面的创新点。（10000字以内）" :autosize="{minRows:3}" maxlength="10000" show-word-limit></Input>
        <Upload
          ref="imgList14"
          :action="imgApi0"
          :headers="{Authorization: token}"
          name="pictures"
          :data="{applyType: 'CREATIVITY_FILE'}"
          :show-upload-list="false"
          :on-success="handleSuccess14"
          :format="['jpg','jpeg']"
          :max-size="10240"
          :on-format-error="handleFormatError"
          :on-exceeded-size="handleMaxSize"
          multiple
          :on-error="handleError"
          :on-progress="handleProgress14"
          accept="image/jpg, image/jpeg">
          <Button class="defaultBtn">上传图片</Button>
        </Upload>
        <span class="note mgTop">非必填，若文字描述不清楚，可上传图片作为辅助说明，图片大小不能超过10MB</span>
        <div class="imgWrap" v-if="imgList14.length>0">
          <div class="imgItem" v-for="(item, i) in imgList14" :key="item.id">
            <template v-if="item.status === 'finished'">
              <img v-if="item.size<20480" class="img" :src="getImageUrl(item.path, 'square-small')" alt="">
              <img v-else class="img" :src="getImageUrl(item.path)" alt="">
              <div class="mask"><Icon type="md-trash" class="delBtn" @click="removeImg14(i)" /></div>
            </template>
            <template v-else>
              <Progress v-if="item.showProgress" :percent="item.percentage" hide-info></Progress>
            </template>
          </div>  
        </div>
      </FormItem>
      <FormItem label="行业引领" prop="industryLeading">
        <Input v-model="info.industryLeading" type="textarea" placeholder="是否发挥了良好的示范、带动作用；是否提高了行业的科技水平和创新能力，推动了成果实施应用。(10000字以内)" :autosize="{minRows:3}" maxlength="10000" show-word-limit></Input>
        <Upload
          ref="imgList15"
          :action="imgApi0"
          :headers="{Authorization: token}"
          name="pictures"
          :data="{applyType: 'INDUSTRYLEADING_FILE'}"
          :show-upload-list="false"
          :on-success="handleSuccess15"
          :format="['jpg','jpeg']"
          :max-size="10240"
          :on-format-error="handleFormatError"
          :on-exceeded-size="handleMaxSize"
          multiple
          :on-error="handleError"
          :on-progress="handleProgress15"
          accept="image/jpg, image/jpeg">
          <Button class="defaultBtn">上传图片</Button>
        </Upload>
        <span class="note mgTop">非必填，若文字描述不清楚，可上传图片作为辅助说明，图片大小不能超过10MB</span>
        <div class="imgWrap" v-if="imgList15.length>0">
          <div class="imgItem" v-for="(item, i) in imgList15" :key="item.id">
            <template v-if="item.status === 'finished'">
              <img v-if="item.size<20480" class="img" :src="getImageUrl(item.path, 'square-small')" alt="">
              <img v-else class="img" :src="getImageUrl(item.path)" alt="">
              <div class="mask"><Icon type="md-trash" class="delBtn" @click="removeImg15(i)" /></div>
            </template>
            <template v-else>
              <Progress v-if="item.showProgress" :percent="item.percentage" hide-info></Progress>
            </template>
          </div>  
        </div>
      </FormItem>
      <FormItem label="健康环保" prop="healthyProtection">
        <Input v-model="info.healthyProtection" type="textarea" placeholder="健康、环保、可持续发展的理念；产品、工艺、应用、生产等环节对环境的影响。(10000字以内)" :autosize="{minRows:3}" maxlength="10000" show-word-limit></Input>
        <Upload
          ref="imgList16"
          :action="imgApi0"
          :headers="{Authorization: token}"
          name="pictures"
          :data="{applyType: 'HEALTHYPROTECTION_FILE'}"
          :show-upload-list="false"
          :on-success="handleSuccess16"
          :format="['jpg','jpeg']"
          :max-size="10240"
          :on-format-error="handleFormatError"
          :on-exceeded-size="handleMaxSize"
          multiple
          :on-error="handleError"
          :on-progress="handleProgress16"
          accept="image/jpg, image/jpeg">
          <Button class="defaultBtn">上传图片</Button>
        </Upload>
        <span class="note mgTop">非必填，若文字描述不清楚，可上传图片作为辅助说明，图片大小不能超过10MB</span>
        <div class="imgWrap" v-if="imgList16.length>0">
          <div class="imgItem" v-for="(item, i) in imgList16" :key="item.id">
            <template v-if="item.status === 'finished'">
              <img v-if="item.size<20480" class="img" :src="getImageUrl(item.path, 'square-small')" alt="">
              <img v-else class="img" :src="getImageUrl(item.path)" alt="">
              <div class="mask"><Icon type="md-trash" class="delBtn" @click="removeImg16(i)" /></div>
            </template>
            <template v-else>
              <Progress v-if="item.showProgress" :percent="item.percentage" hide-info></Progress>
            </template>
          </div>  
        </div>
      </FormItem>
      <FormItem label="综合效益" prop="benefits">
        <Input v-model="info.benefits" type="textarea" placeholder="经济效益、工艺技术指标、社会效益。(10000字以内)" :autosize="{minRows:3}" maxlength="10000" show-word-limit></Input>
        <Upload
          ref="imgList17"
          :action="imgApi0"
          :headers="{Authorization: token}"
          name="pictures"
          :data="{applyType: 'BENEFITS_FILE'}"
          :show-upload-list="false"
          :on-success="handleSuccess17"
          :format="['jpg','jpeg']"
          :max-size="10240"
          :on-format-error="handleFormatError"
          :on-exceeded-size="handleMaxSize"
          multiple
          :on-error="handleError"
          :on-progress="handleProgress17"
          accept="image/jpg, image/jpeg">
          <Button class="defaultBtn">上传图片</Button>
        </Upload>
        <span class="note mgTop">非必填，若文字描述不清楚，可上传图片作为辅助说明，图片大小不能超过10MB</span>
        <div class="imgWrap" v-if="imgList17.length>0">
          <div class="imgItem" v-for="(item, i) in imgList17" :key="item.id">
            <template v-if="item.status === 'finished'">
              <img v-if="item.size<20480" class="img" :src="getImageUrl(item.path, 'square-small')" alt="">
              <img v-else class="img" :src="getImageUrl(item.path)" alt="">
              <div class="mask"><Icon type="md-trash" class="delBtn" @click="removeImg17(i)" /></div>
            </template>
            <template v-else>
              <Progress v-if="item.showProgress" :percent="item.percentage" hide-info></Progress>
            </template>
          </div>  
        </div>
      </FormItem>
      <Row :gutter="15">
        <Col span="12">
          <FormItem label="宣传视频" prop="video">
            <Input v-model="info.video" placeholder="粘贴视频网址即可"></Input>
          </FormItem>
        </Col>
      </Row>
      <FormItem label="设计图" prop="designPic">
        <p>
          <Upload
            ref="imgList1"
            :action="imgApi0"
            :headers="{Authorization: token}"
            name="pictures"
            :data="{applyType: 'DESIGN_PIC'}"
            :show-upload-list="false"
            :on-success="handleSuccess1"
            :format="['jpg','jpeg']"
            :max-size="20480"
            :on-format-error="handleFormatError"
            :on-exceeded-size="handleMaxSize2"
            :before-upload="handleBeforeUpload1"
            multiple
            :on-error="handleError"
            :on-progress="handleProgress1"
            accept="image/jpg, image/jpeg">
            <Button class="defaultBtn">上传图片</Button>
          </Upload>
          <Tooltip content="图片处理详解" theme="light">
            <a :href="getFileUrl('file/%E5%9B%BE%E7%89%87%E8%AE%BE%E7%BD%AE%E6%AD%A5%E9%AA%A4.pdf')"  target="_blank">
              <Icon class="tipNote" type="ios-book"  color="#277A44"/>
            </a>
          </Tooltip>
        </p>
        <p class="note noteBlock">图片要求：分辨率：300dpi；颜色模式：CMYK；文件格式：“.JPG”；图片大小：不低于2M；图片数量：不超过50张</p>
        <div class="imgWrap" v-if="imgList1.length>0">
          <div class="imgItem" v-for="(item, i) in imgList1" :key="item.id">
            <template v-if="item.status === 'finished'">
              <img v-if="item.size<20480" class="img" :src="getImageUrl(item.path, 'square-small')" alt="">
              <img v-else class="img" :src="getImageUrl(item.path)" alt="">
              <div class="mask"><Icon type="md-trash" class="delBtn" @click="removeImg1(i)" /></div>
            </template>
            <template v-else>
              <Progress v-if="item.showProgress" :percent="item.percentage" hide-info></Progress>
            </template>
          </div>  
        </div>
      </FormItem>
      <FormItem label="实景/实物图" prop="realPic">
        <p>
          <Upload
            ref="imgList2"
            :action="imgApi0"
            :headers="{Authorization: token}"
            name="pictures"
            :data="{applyType: 'REAL_PIC'}"
            :show-upload-list="false"
            :on-success="handleSuccess2"
            :format="['jpg','jpeg']"
            :max-size="20480"
            :on-format-error="handleFormatError"
            :on-exceeded-size="handleMaxSize2"
            :before-upload="handleBeforeUpload2"
            multiple
            :on-error="handleError"
            :on-progress="handleProgress2"
            accept="image/jpg, image/jpeg">
            <Button class="defaultBtn">上传图片</Button>
          </Upload>
        </p>
        <p class="note noteBlock">图片要求：分辨率：300dpi；颜色模式：CMYK；文件格式：“.JPG”；图片大小：不低于2M；图片数量：不超过50张</p>
        <div class="imgWrap" v-if="imgList2.length>0">
          <div class="imgItem" v-for="(item, i) in imgList2" :key="item.id">
            <template v-if="item.status === 'finished'">
              <img v-if="item.size<20480" class="img" :src="getImageUrl(item.path, 'square-small')" alt="">
              <img v-else class="img" :src="getImageUrl(item.path)" alt="">
              <div class="mask"><Icon type="md-trash" class="delBtn" @click="removeImg2(i)" /></div>
            </template>
            <template v-else>
              <Progress v-if="item.showProgress" :percent="item.percentage" hide-info></Progress>
            </template>
          </div>  
        </div>
      </FormItem>
      <FormItem label="重点突出创新内容的图片" prop="innovatePic">
        <p>
          <Upload
            ref="imgList3"
            :action="imgApi0"
            :headers="{Authorization: token}"
            name="pictures"
            :data="{applyType: 'INNOVATE_PIC'}"
            :show-upload-list="false"
            :on-success="handleSuccess3"
            :format="['jpg','jpeg']"
            :max-size="20480"
            :on-format-error="handleFormatError"
            :on-exceeded-size="handleMaxSize2"
            :before-upload="handleBeforeUpload3"
            multiple
            :on-error="handleError" 
            :on-progress="handleProgress3"
            accept="image/jpg, image/jpeg">
            <Button class="defaultBtn">上传图片</Button>
          </Upload>
          <Tooltip max-width="370" content="照片尽可能从多个角度拍摄，同时尽可能多的添加项目细部图、施工照片、设计图等能重点突出创新的图片。" theme="light">
            <Icon class="tipNote" type="ios-warning" color="#E9A520" />
          </Tooltip>
        </p>
        <p class="note noteBlock">图片要求：分辨率：300dpi；颜色模式：CMYK；文件格式：“.JPG”；图片大小：不低于2M；图片数量：不超过50张</p>
        <div class="imgWrap" v-if="imgList3.length>0">
          <div class="imgItem" v-for="(item, i) in imgList3" :key="item.id">
            <template v-if="item.status === 'finished'">
              <img v-if="item.size<20480" class="img" :src="getImageUrl(item.path, 'square-small')" alt="">
              <img v-else class="img" :src="getImageUrl(item.path)" alt="">
              <div class="mask"><Icon type="md-trash" class="delBtn" @click="removeImg3(i)" /></div>
            </template>
            <template v-else>
              <Progress v-if="item.showProgress" :percent="item.percentage" hide-info></Progress>
            </template>
          </div>  
        </div>
      </FormItem>
      <FormItem label="产品执行标准">
        <p>
          <Upload
            ref="imgList5"
            :action="imgApi0"
            :headers="{Authorization: token}"
            name="pictures"
            :data="{applyType: 'STANDARD_FILE'}"
            :show-upload-list="false"
            :on-success="handleSuccess5"
            :format="['jpg','jpeg','pdf']"
            :max-size="20480"
            :on-format-error="handleFormatError"
            :on-exceeded-size="handleMaxSize2"
            multiple
            :on-error="handleError"
            :on-progress="handleProgress5"
            accept="image/jpg, image/jpeg, application/pdf">
            <Button class="defaultBtn">上传扫描件</Button>
          </Upload>
          <!-- :before-upload="handleBeforeUpload5" -->
          <span class="note">文件格式：“.JPG”或“.PDF”</span>
        </p>
        <div class="imgWrap" v-if="imgList5.length>0">
          <div class="imgItem" v-for="(item, i) in imgList5" :key="item.id">
            <template v-if="item.status === 'finished'">
              <p v-if="item.fileType=='FILE'" class="fileName threeRows">{{item.name}}</p>
              <img v-else-if="item.size<20480" class="img" :src="getImageUrl(item.path, 'square-small')" alt="">
              <img v-else class="img" :src="getImageUrl(item.path)" alt="">
              <div class="mask"><Icon type="md-trash" class="delBtn" @click="removeImg5(i)" /></div>
            </template>
            <template v-else>
              <Progress v-if="item.showProgress" :percent="item.percentage" hide-info></Progress>
            </template>
          </div>  
        </div>
      </FormItem>
      <FormItem label="专利技术">
        <p>
          <Upload
            ref="imgList6"
            :action="imgApi0"
            :headers="{Authorization: token}"
            name="pictures"
            :data="{applyType: 'PATENT_FILE'}"
            :show-upload-list="false"
            :on-success="handleSuccess6"
            :format="['jpg','jpeg','pdf']"
            :max-size="20480"
            :on-format-error="handleFormatError"
            :on-exceeded-size="handleMaxSize2"
            multiple
            :on-error="handleError"
            :on-progress="handleProgress6"
            accept="image/jpg, image/jpeg, application/pdf">
            <Button class="defaultBtn">上传扫描件</Button>
          </Upload>
          <!-- :before-upload="handleBeforeUpload6" -->
          <span class="note">文件格式：“.JPG”或“.PDF”</span>
        </p>
        <div class="imgWrap" v-if="imgList6.length>0">
          <div class="imgItem" v-for="(item, i) in imgList6" :key="item.id">
            <template v-if="item.status === 'finished'">
              <p v-if="item.fileType=='FILE'" class="fileName threeRows">{{item.name}}</p>
              <img v-else-if="item.size<20480" class="img" :src="getImageUrl(item.path, 'square-small')" alt="">
              <img v-else class="img" :src="getImageUrl(item.path)" alt="">
              <div class="mask"><Icon type="md-trash" class="delBtn" @click="removeImg6(i)" /></div>
            </template>
            <template v-else>
              <Progress v-if="item.showProgress" :percent="item.percentage" hide-info></Progress>
            </template>
          </div>  
        </div>
      </FormItem>
      <FormItem label="科研成果">
        <p>
          <Upload
            ref="imgList7"
            :action="imgApi0"
            :headers="{Authorization: token}"
            name="pictures"
            :data="{applyType: 'ACHIEVEMENTS_FILE'}"
            :show-upload-list="false"
            :on-success="handleSuccess7"
            :format="['jpg','jpeg','pdf']"
            :max-size="20480"
            :on-format-error="handleFormatError"
            :on-exceeded-size="handleMaxSize2"
            multiple
            :on-error="handleError"
            :on-progress="handleProgress7"
            accept="image/jpg, image/jpeg, application/pdf">
            <Button class="defaultBtn">上传扫描件</Button>
          </Upload>
          <!-- :before-upload="handleBeforeUpload7" -->
          <span class="note">文件格式：“.JPG”或“.PDF”</span>
        </p>
        <div class="imgWrap" v-if="imgList7.length>0">
          <div class="imgItem" v-for="(item, i) in imgList7" :key="item.id">
            <template v-if="item.status === 'finished'">
              <p v-if="item.fileType=='FILE'" class="fileName threeRows">{{item.name}}</p>
              <img v-else-if="item.size<20480" class="img" :src="getImageUrl(item.path, 'square-small')" alt="">
              <img v-else class="img" :src="getImageUrl(item.path)" alt="">
              <div class="mask"><Icon type="md-trash" class="delBtn" @click="removeImg7(i)" /></div>
            </template>
            <template v-else>
              <Progress v-if="item.showProgress" :percent="item.percentage" hide-info></Progress>
            </template>
          </div>  
        </div>
      </FormItem>
      <FormItem label="项目所获奖励">
        <p>
          <Upload
            ref="imgList8"
            :action="imgApi0"
            :headers="{Authorization: token}"
            name="pictures"
            :data="{applyType: 'REWARD_FILE'}"
            :show-upload-list="false"
            :on-success="handleSuccess8"
            :format="['jpg','jpeg','pdf']"
            :max-size="20480"
            :on-format-error="handleFormatError"
            :on-exceeded-size="handleMaxSize2"
            multiple
            :on-error="handleError"
            :on-progress="handleProgress8"
            accept="image/jpg, image/jpeg, application/pdf">
            <Button class="defaultBtn">上传扫描件</Button>
          </Upload>
          <!-- :before-upload="handleBeforeUpload8" -->
          <span class="note">文件格式：“.JPG”或“.PDF”</span>
        </p>
        <div class="imgWrap" v-if="imgList8.length>0">
          <div class="imgItem" v-for="(item, i) in imgList8" :key="item.id">
            <template v-if="item.status === 'finished'">
              <p v-if="item.fileType=='FILE'" class="fileName threeRows">{{item.name}}</p>
              <img v-else-if="item.size<20480" class="img" :src="getImageUrl(item.path, 'square-small')" alt="">
              <img v-else class="img" :src="getImageUrl(item.path)" alt="">
              <div class="mask"><Icon type="md-trash" class="delBtn" @click="removeImg8(i)" /></div>
            </template>
            <template v-else>
              <Progress v-if="item.showProgress" :percent="item.percentage" hide-info></Progress>
            </template>
          </div>  
        </div>
      </FormItem>
      <FormItem label="用户评价报告">
        <p>
          <Upload
            ref="imgList9"
            :action="imgApi0"
            :headers="{Authorization: token}"
            name="pictures"
            :data="{applyType: 'EVALUATE_FILE'}"
            :show-upload-list="false"
            :on-success="handleSuccess9"
            :format="['jpg','jpeg','pdf']"
            :max-size="20480"
            :on-format-error="handleFormatError"
            :on-exceeded-size="handleMaxSize2"
            multiple
            :on-error="handleError"
            :on-progress="handleProgress9"
            accept="image/jpg, image/jpeg, application/pdf">
            <Button class="defaultBtn">上传扫描件</Button>
          </Upload>
          <!-- :before-upload="handleBeforeUpload9" -->
          <span class="note">文件格式：“.JPG”或“.PDF”</span>
        </p>
        <div class="imgWrap" v-if="imgList9.length>0">
          <div class="imgItem" v-for="(item, i) in imgList9" :key="item.id">
            <template v-if="item.status === 'finished'">
              <p v-if="item.fileType=='FILE'" class="fileName threeRows">{{item.name}}</p>
              <img v-else-if="item.size<20480" class="img" :src="getImageUrl(item.path, 'square-small')" alt="">
              <img v-else class="img" :src="getImageUrl(item.path)" alt="">
              <div class="mask"><Icon type="md-trash" class="delBtn" @click="removeImg9(i)" /></div>
            </template>
            <template v-else>
              <Progress v-if="item.showProgress" :percent="item.percentage" hide-info></Progress>
            </template>
          </div>  
        </div>
      </FormItem>
      <FormItem label="推荐单位意见">
        <p>
          <Upload
            ref="imgList10"
            :action="imgApi0"
            :headers="{Authorization: token}"
            name="pictures"
            :data="{applyType: 'RECOMMEND_FILE'}"
            :show-upload-list="false"
            :on-success="handleSuccess10"
            :format="['jpg','jpeg','pdf']"
            :max-size="20480"
            :on-format-error="handleFormatError"
            :on-exceeded-size="handleMaxSize2"
            multiple
            :on-error="handleError"
            :on-progress="handleProgress10"
            accept="image/jpg, image/jpeg, application/pdf">
            <Button class="defaultBtn">上传扫描件</Button>
          </Upload>
          <!-- :before-upload="handleBeforeUpload10" -->
          <span class="note">文件格式：“.JPG”或“.PDF”</span>
        </p>
        <div class="imgWrap" v-if="imgList10.length>0">
          <div class="imgItem" v-for="(item, i) in imgList10" :key="item.id">
            <template v-if="item.status === 'finished'">
              <p v-if="item.fileType=='FILE'" class="fileName threeRows">{{item.name}}</p>
              <img v-else-if="item.size<20480" class="img" :src="getImageUrl(item.path, 'square-small')" alt="">
              <img v-else class="img" :src="getImageUrl(item.path)" alt="">
              <div class="mask"><Icon type="md-trash" class="delBtn" @click="removeImg10(i)" /></div>
            </template>
            <template v-else>
              <Progress v-if="item.showProgress" :percent="item.percentage" hide-info></Progress>
            </template>
          </div>  
        </div>
      </FormItem>
      <FormItem label="行业内类似奖项">
        <p>
          <Upload
            ref="imgList11"
            :action="imgApi0"
            :headers="{Authorization: token}"
            name="pictures"
            :data="{applyType: 'PRIZE_FILE'}"
            :show-upload-list="false"
            :on-success="handleSuccess11"
            :format="['jpg','jpeg','pdf']"
            :max-size="20480"
            :on-format-error="handleFormatError"
            :on-exceeded-size="handleMaxSize2"
            multiple
            :on-error="handleError"
            :on-progress="handleProgress11"
            accept="image/jpg, image/jpeg, application/pdf">
            <Button class="defaultBtn">上传扫描件</Button>
          </Upload>
          <!-- :before-upload="handleBeforeUpload11" -->
          <span class="note">文件格式：“.JPG”或“.PDF”</span>
        </p>
        <div class="imgWrap" v-if="imgList11.length>0">
          <div class="imgItem" v-for="(item, i) in imgList11" :key="item.id">
            <template v-if="item.status === 'finished'">
              <p v-if="item.fileType=='FILE'" class="fileName threeRows">{{item.name}}</p>
              <img v-else-if="item.size<20480" class="img" :src="getImageUrl(item.path, 'square-small')" alt="">
              <img v-else class="img" :src="getImageUrl(item.path)" alt="">
              <div class="mask"><Icon type="md-trash" class="delBtn" @click="removeImg11(i)" /></div>
            </template>
            <template v-else>
              <Progress v-if="item.showProgress" :percent="item.percentage" hide-info></Progress>
            </template>
          </div>  
        </div>
      </FormItem>
      <FormItem label="其它证明材料">
        <p>
          <Upload
            ref="imgList4"
            :action="imgApi0"
            :headers="{Authorization: token}"
            name="pictures"
            :data="{applyType: 'METHOD_FILE'}"
            :show-upload-list="false"
            :on-success="handleSuccess4"
            :format="['jpg','jpeg','pdf']"
            :max-size="20480"
            :on-format-error="handleFormatError"
            :on-exceeded-size="handleMaxSize2"
            multiple
            :on-error="handleError"
            :on-progress="handleProgress4"
            accept="image/jpg, image/jpeg, application/pdf">
            <Button class="defaultBtn">上传扫描件</Button>
          </Upload>
          <!-- :before-upload="handleBeforeUpload4" -->
          <span class="note">文件格式：“.JPG”或“.PDF”</span>
        </p>
        <div class="imgWrap" v-if="imgList4.length>0">
          <div class="imgItem" v-for="(item, i) in imgList4" :key="item.id">
            <template v-if="item.status === 'finished'">
              <p v-if="item.fileType=='FILE'" class="fileName threeRows">{{item.name}}</p>
              <img v-else-if="item.size<20480" class="img" :src="getImageUrl(item.path, 'square-small')" alt="">
              <img v-else class="img" :src="getImageUrl(item.path)" alt="">
              <div class="mask"><Icon type="md-trash" class="delBtn" @click="removeImg4(i)" /></div>
            </template>
            <template v-else>
              <Progress v-if="item.showProgress" :percent="item.percentage" hide-info></Progress>
            </template>
          </div>  
        </div>
      </FormItem>
      <FormItem label="" prop="accept">
        <Checkbox v-model="info.accept" size="small">
          <span>我了解并同意</span>
          <span class="colorGreen">《版权及其它说明》</span>
        </Checkbox>
        <div class="explainWrap">
          <p>版权及其他说明:</p>
          <p>1. 申报作品均为原创，未侵犯任何他人的任何专利、著作权、商标权及其他知识产权。申报作品发生任何侵权行为，一经核实，主办方将取消其参赛及获奖资格。</p>
          <p>2. 大奖主办单位享有对申报作品及相关材料进行宣传使用、公开展览和出版发行等权利。</p>
          <p>3. 申报作品如有质量问题将采取评审一票否决制，不予入选。</p>
          <p>4. 申报者提交作品之前需仔细阅读上述条款，充分理解并表示同意，如果本次申报作品或者申报对第三方造成侵权、损害或者其他纠纷，由申报者本人承担一切法律责任，与主办单位无关。</p>
          <p>5. 大奖主办单位对申报及评选具有最终解释权、裁定权。</p>
        </div>
      </FormItem>
      <p class="center"><Button class="defaultBtn" :loading="submitting" @click="submit">保存并报名</Button></p>
      <p class="center mgTop"><Button class="colorGreen" type="text" @click="changeShows('project')">{{info.id?'取消保存':'返回项目列表'}}</Button></p>
    </Form>
  </div>
</template>

<script>
import userService from "@/api/user";
import { baseUrl } from "@/config/env";
export default {
  data() {
    return {
      imgApi0: `${baseUrl}pictures`,
      // imgApi: `${baseUrl}picture/upload`,
      token: this.$store.getters.token,
      searchName: null,
      companyList: [],
      show: "project",
      list: [],
      currentPage: 1,
      total: 0,
      submitting: false,
      info: {
        designPictures: [],
        realPictures: [],
        innovatePictures: [],
        methodFile: [],
        standardFile: [],
        patentFile: [],
        achievementsFile: [],
        rewardFile: [],
        evaluateFile: [],
        recommendFile: [],
        prizeFile: [],
        acceptanceFile: [],
        descriptionFile: [],
        creativityFile: [],
        industryLeadingFile: [],
        healthyProtectionFile: [],
        benefitsFile: [],
        regId: ""
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入参评项目名称",
            trigger: "blur,change"
          },
          {
            required: true,
            message: "参评项目名称60字以内",
            trigger: "blur,change",
            max: 60
          }
        ],
        type: [
          {
            required: true,
            message: "请选择参评类别",
            trigger: "blur,change"
          }
        ],
        subType: [
          {
            required: true,
            message: "请选择参评类别",
            validator: (rule, value) => {
              if (this.info.type == "建筑应用创新单项类" && !value) {
                return false;
              }
              return true;
            },
            trigger: "blur,change"
          }
        ],
        completionDate: [
          {
            required: true,
            message: "请输入参评项目完成时间（年月）",
            validator: (rule, value) => {
              if (!value) {
                return false;
              }
              return true;
            },
            trigger: "blur,change"
          }
        ],
        regId: [
          {
            required: true,
            message: "请选择所属参评单位",
            trigger: "blur,change"
          }
        ],
        address: [
          {
            required: true,
            message: "请输入项目所在地",
            trigger: "blur,change"
          },
          {
            required: true,
            message: "项目所在地180字以内",
            trigger: "blur,change",
            max: 180
          }
        ],
        scale: [
          {
            required: false,
            message: "项目规模30字以内",
            trigger: "blur,change",
            max: 30
          }
        ],
        functionType: [
          {
            required: false,
            message: "功能类型60字以内",
            trigger: "blur,change",
            max: 60
          }
        ],
        video: [
          {
            required: false,
            message: "宣传视频网址500字以内",
            trigger: "blur,change",
            max: 500
          },
          {
            pattern: /((http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-,@?^=%&:/~#]*[\w\-?^=%&/~#])?)/,
            message: "请输入正确的视频网址",
            trigger: "blur,change"
          }
        ],
        description: [
          { required: true, message: "请输入项目综述", trigger: "blur,change" }
        ],
        creativity: [
          { required: true, message: "请输入创新性", trigger: "blur,change" }
        ],
        industryLeading: [
          { required: true, message: "请输入行业引领", trigger: "blur,change" }
        ],
        healthyProtection: [
          { required: true, message: "请输入健康环保", trigger: "blur,change" }
        ],
        benefits: [
          { required: true, message: "请输入综合效益", trigger: "blur,change" }
        ],
        // designPic: [
        //   {
        //     required: true,
        //     message: "请上传产品主要设计图",
        //     validator: () => {
        //       if (this.info.designPictures.length == 0) {
        //         return false;
        //       }
        //       return true;
        //     }
        //   }
        // ],
        realPic: [
          {
            required: true,
            message: "请上传产品实景/实物图",
            validator: () => {
              if (this.info.realPictures.length == 0) {
                return false;
              }
              return true;
            }
          }
        ],
        innovatePic: [
          {
            required: true,
            message: "请上传重点突出创新内容的图片",
            validator: () => {
              if (this.info.innovatePictures.length == 0) {
                return false;
              }
              return true;
            }
          }
        ],
        accept: [
          {
            required: true,
            message: "请勾选了解并同意《版权及其它说明》",
            validator: (rule, value) => {
              if (value !== true) {
                return false;
              }
              return true;
            },
            trigger: "change"
          }
        ]
      },
      imgList1: [],
      imgList2: [],
      imgList3: [],
      imgList4: [],
      imgList5: [],
      imgList6: [],
      imgList7: [],
      imgList8: [],
      imgList9: [],
      imgList10: [],
      imgList11: [],
      imgList12: [],
      imgList13: [],
      imgList14: [],
      imgList15: [],
      imgList16: [],
      imgList17: [],

      timer: null,
      deadline: {}
    };
  },
  mounted() {
    if (this.gIsLogin()) {
      // 先获取参评单位信息，如果没有提交参评单位信息，提示先去提交参评单位信息
      this.getCompetitionRegistration();
      // console.log(this.info)
      this.getEndDate()
    } else {
      this.$router.push(`/user/login?fromUrl=${this.$route.fullPath}`);
    }
  },
  methods: {
    // 获取时间
    getEndDate(){
      userService.getEndDate(0)
        .then(res => {
          if (res.errorNo == 0) {
            // 提交成功执行
            this.deadline = res.data || {}
          } else {
            this.$Message.error(res.errorMessage);
          }
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
        });
    },
    // 新增项目
    addProject(){
      let deadTime = new Date(this.deadline.addEndTime), now = new Date()
      if(deadTime<now){
        this.$Modal.info({
          title: '提示',
          content: '报名已经截至，不能再次提交项目，如有问题，请联系组委会。'
        })
        return
      }
      this.getEmptyProject('new')
    },
    // 获取项目审核状态文字
    getStatusText(s) {
      // if (s == "SUCCESS") {
      //   return "已通过";
      // }
      // if (s == "FAILED") {
      //   return "未通过";
      // }
      if (s == "EMPTY") {
        return "未提交";
      } 
      return "待审核";
    },
    // 设置自动保存
    autoSubmit() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      } else {
        let _this = this;
        this.timer = setInterval(function() {
          let data = _this.getDataToServe();
          if(!data.checkStatus){
            data.checkStatus = "EMPTY";
          }else{
            data.checkStatus = "CHECHING";
          }
          if(data.name && data.type && data.regId){
            userService
              .saveCompetitionProject(data)
              .then(res => {
                if (res.errorNo == 0) {
                  if (!data.id) {
                    _this.info.id = res.data;
                  }
                }
              })
              .catch(() => {});
          }
        }, 180000);
      }
    },
    // 获取报名信息
    getCompetitionRegistration() {
      let userId = this.$store.getters.userInfo.id;
      userService
        .getCompetitionRegistration({
          "userId.eq": userId,
          size: 100
        })
        .then(res => {
          if (res.errorNo == 0) {
            if (res.data.length > 0) {
              this.companyList = res.data;
              for (let i = 0; i < this.companyList.length; i++) {
                this.companyList[i].id = this.companyList[i].id + "";
              }
              // 先判断是否有未提交的项目
              this.getEmptyProject()
              this.getCompetitionProjects()
            } else {
              let _this = this;
              this.$Modal.info({
                title: "提示",
                content:
                  "您还没有填写参评单位信息，请先提交参评单位信息后再来上传项目信息。",
                okText: "填写参评单位信息",
                onOk: function() {
                  _this.$router.push("/declare/basic");
                }
              });
            }
          } else {
            this.$Message.error(res.errorMessage);
          }
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
        });
    },
    // 根据名称查询项目
    search() {
      this.currentPage = 1;
      this.getCompetitionProjects();
    },
    // 换页
    changePage(p) {
      this.currentPage = p;
      this.getCompetitionProjects();
      document.getElementsByClassName("project")[0].scrollTop = 0;
    },
    // 判断是否有未提交的项目
    getEmptyProject(key){
      let userId = this.$store.getters.userInfo.id;
      let conditions = {
        "userId.eq": userId,
        'checkStatus.eq': 'EMPTY',
        page: 0,
        size: 1,
        sort: "id,desc"
      }
      userService
        .getProjects(conditions).then(res => {
          if (res.errorNo == 0) {
            if (res.data.length > 0) {
              this.noteEmpty(res.data[0], key)
            }else{
              if(key=='new'){
                this.changeShows('form')
              }
            }
          } else {
            this.$Message.error(res.errorMessage);
          }
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
        });
    },
    // 提示有未提交的项目，是否继续编辑
    noteEmpty(info, key){
      let _this = this
      this.$Modal.confirm({
        title: "您有未提交的项目 “" + info.name + "”，是否继续编辑？",
        okText: "继续编辑",
        cancelText: key=='new'?"重新添加新项目":"取消编辑",
        onOk: function() {
          _this.editProject(info);
        },
        onCancel: function() {
          if(key=='new'){
            _this.changeShows('form')
          }
        }
      })
    },
    // 获取项目信息
    getCompetitionProjects() {
      let userId = this.$store.getters.userInfo.id;
      let conditions = {
        "userId.eq": userId,
        'checkStatus.ne': 'EMPTY',
        "name.contains": this.searchName || null,
        page: this.currentPage - 1,
        size: 19,
        sort: "id,desc"
      }
      userService
        .getProjects(conditions).then(res => {
          if (res.errorNo == 0) {
            if (res.data.length > 0) {
              this.list = res.data;
              this.currentPage == 1 && (this.total = res.totalSize); 
            } else {
              if (this.searchName) {
                this.list = [];
                this.total = 0;
              } else {
                let deadTime = new Date(this.deadline.addEndTime), now = new Date()
                if(deadTime<now){
                  this.list = res.data;
                  this.currentPage == 1 && (this.total = res.totalSize);
                }else{
                  this.show = "form";
                  this.defaultImgList();
                  this.autoSubmit();
                }
              }
            }
          } else {
            this.$Message.error(res.errorMessage);
          }
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
        });
    },
    // 编辑项目信息
    editProject(info) {
      let deadTime = new Date(this.deadline.editEndTime), now = new Date()
      if(deadTime<now){
        this.$Modal.info({
          title: '提示',
          content: '报名已经截至，不能再次修改项目信息，如有问题，请联系组委会。'
        })
        return
      }
      this.initImgList();
      this.info = Object.assign({}, info);

      this.info.regId = this.info.regId + "";

      this.info.accept = true;
      for (let i = 0; i < this.info.designPictures.length; i++) {
        this.info.designPictures[i].status = "finished";
        this.imgList1.push(this.info.designPictures[i]);
      }
      for (let i = 0; i < this.info.realPictures.length; i++) {
        this.info.realPictures[i].status = "finished";
        this.imgList2.push(this.info.realPictures[i]);
      }
      for (let i = 0; i < this.info.innovatePictures.length; i++) {
        this.info.innovatePictures[i].status = "finished";
        this.imgList3.push(this.info.innovatePictures[i]);
      }
      for (let i = 0; i < this.info.methodFile.length; i++) {
        this.info.methodFile[i].status = "finished";
        this.imgList4.push(this.info.methodFile[i]);
      }
      for (let i = 0; i < this.info.standardFile.length; i++) {
        this.info.standardFile[i].status = "finished";
        this.imgList5.push(this.info.standardFile[i]);
      }
      for (let i = 0; i < this.info.patentFile.length; i++) {
        this.info.patentFile[i].status = "finished";
        this.imgList6.push(this.info.patentFile[i]);
      }
      for (let i = 0; i < this.info.achievementsFile.length; i++) {
        this.info.achievementsFile[i].status = "finished";
        this.imgList7.push(this.info.achievementsFile[i]);
      }
      for (let i = 0; i < this.info.rewardFile.length; i++) {
        this.info.rewardFile[i].status = "finished";
        this.imgList8.push(this.info.rewardFile[i]);
      }
      for (let i = 0; i < this.info.evaluateFile.length; i++) {
        this.info.evaluateFile[i].status = "finished";
        this.imgList9.push(this.info.evaluateFile[i]);
      }
      for (let i = 0; i < this.info.recommendFile.length; i++) {
        this.info.recommendFile[i].status = "finished";
        this.imgList10.push(this.info.recommendFile[i]);
      }
      for (let i = 0; i < this.info.prizeFile.length; i++) {
        this.info.prizeFile[i].status = "finished";
        this.imgList11.push(this.info.prizeFile[i]);
      }
      if (this.info.acceptanceFile) {
        for (let i = 0; i < this.info.acceptanceFile.length; i++) {
          this.info.acceptanceFile[i].status = "finished";
          this.imgList12.push(this.info.acceptanceFile[i]);
        }
      } else {
        this.info.acceptanceFile = [];
      }
      if (this.info.descriptionFile) {
        for (let i = 0; i < this.info.descriptionFile.length; i++) {
          this.info.descriptionFile[i].status = "finished";
          this.imgList13.push(this.info.descriptionFile[i]);
        }
      } else {
        this.info.descriptionFile = [];
      }
      if (this.info.creativityFile) {
        for (let i = 0; i < this.info.creativityFile.length; i++) {
          this.info.creativityFile[i].status = "finished";
          this.imgList14.push(this.info.creativityFile[i]);
        }
      } else {
        this.info.creativityFile = [];
      }
      if (this.info.industryLeadingFile) {
        for (let i = 0; i < this.info.industryLeadingFile.length; i++) {
          this.info.industryLeadingFile[i].status = "finished";
          this.imgList15.push(this.info.industryLeadingFile[i]);
        }
      } else {
        this.info.industryLeadingFile = [];
      }
      if (this.info.healthyProtectionFile) {
        for (let i = 0; i < this.info.healthyProtectionFile.length; i++) {
          this.info.healthyProtectionFile[i].status = "finished";
          this.imgList16.push(this.info.healthyProtectionFile[i]);
        }
      } else {
        this.info.healthyProtectionFile = [];
      }
      if (this.info.benefitsFile) {
        for (let i = 0; i < this.info.benefitsFile.length; i++) {
          this.info.benefitsFile[i].status = "finished";
          this.imgList17.push(this.info.benefitsFile[i]);
        }
      } else {
        this.info.benefitsFile = [];
      }
      this.show = "form";
      this.defaultImgList();
      this.autoSubmit();
    },
    // 删除项目
    delProjectConfirm(i) {
      let deadTime = new Date(this.deadline.editEndTime), now = new Date()
      if(deadTime<now){
        this.$Modal.info({
          title: '提示',
          content: '报名已经截至，不能删除项目信息，如有问题，请联系组委会。'
        })
        return
      }
      let info = this.list[i],
        _this = this;
      this.$Modal.confirm({
        title: "提示",
        content: `项目删除后将不能恢复，您确定要删除参评项目“${info.name}”吗？`,
        okText: "删除",
        onOk: function() {
          _this.delProject(i, info.id);
        }
      });
    },
    // 删除项目
    delProject(i, id) {
      userService
        .deleteOneProject(id)
        .then(res => {
          if (res.errorNo == 0) {
            this.$Message.success("项目删除成功");
            this.list.splice(i, 1);
          } else {
            this.$Message.error(res.errorMessage);
          }
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
        });
    },
    // 切换显示项目还是表单
    changeShows(v) {
      if (v == this.show) {
        return;
      }
      let deadTime = new Date(this.deadline.addEndTime), now = new Date()
      if(v == "form" && deadTime<now){
        this.$Modal.info({
          title: '提示',
          content: '报名已经截至，不能再次提交项目，如有问题，请联系组委会。'
        })
        return
      }
      if (v == "form") {
        this.info = {
          designPictures: [],
          realPictures: [],
          innovatePictures: [],
          methodFile: [],
          standardFile: [],
          patentFile: [],
          achievementsFile: [],
          rewardFile: [],
          evaluateFile: [],
          recommendFile: [],
          prizeFile: [],
          acceptanceFile: [],
          descriptionFile: [],
          creativityFile: [],
          industryLeadingFile: [],
          healthyProtectionFile: [],
          benefitsFile: [],
          regId: ""
        };
        this.initImgList();
        this.defaultImgList();
        this.$refs.form.resetFields();
        this.autoSubmit();
      } else {
        if(this.timer){
          this.autoSubmit();
        }
      }
      this.show = v;
    },
    // 设置完成时间
    setDate(val) {
      this.info.completionDate = val;
    },
    // 删除图片/文件
    delImg() {},
    // 初始化文件列表
    initImgList() {
      this.imgList1 = [];
      this.imgList2 = [];
      this.imgList3 = [];
      this.imgList4 = [];
      this.imgList5 = [];
      this.imgList6 = [];
      this.imgList7 = [];
      this.imgList8 = [];
      this.imgList9 = [];
      this.imgList10 = [];
      this.imgList11 = [];
      this.imgList12 = [];
      this.imgList13 = [];
      this.imgList14 = [];
      this.imgList15 = [];
      this.imgList16 = [];
      this.imgList17 = [];
    },
    defaultImgList() {
      this.$refs.imgList1.fileList = this.imgList1;
      this.$refs.imgList2.fileList = this.imgList2;
      this.$refs.imgList3.fileList = this.imgList3;
      this.$refs.imgList4.fileList = this.imgList4;
      this.$refs.imgList5.fileList = this.imgList5;
      this.$refs.imgList6.fileList = this.imgList6;
      this.$refs.imgList7.fileList = this.imgList7;
      this.$refs.imgList8.fileList = this.imgList8;
      this.$refs.imgList9.fileList = this.imgList9;
      this.$refs.imgList10.fileList = this.imgList10;
      this.$refs.imgList11.fileList = this.imgList11;
      this.$refs.imgList12.fileList = this.imgList12;
      this.$refs.imgList13.fileList = this.imgList13;
      this.$refs.imgList14.fileList = this.imgList14;
      this.$refs.imgList15.fileList = this.imgList15;
      this.$refs.imgList16.fileList = this.imgList16;
      this.$refs.imgList17.fileList = this.imgList17;
    },
    // 提交表单
    submit() {
      let now = new Date()
      if(this.info.id){
        let editTime = new Date(this.deadline.editEndTime)
        if(editTime<now){
          this.$Modal.info({
            title: '提示',
            content: '报名已经截至，不能再次修改项目信息，如有问题，请联系组委会。'
          })
          return
        } 
      }else{
        let addTime = new Date(this.deadline.addEndTime)
        if(addTime<now){
          this.$Modal.info({
            title: '提示',
            content: '报名已经截至，不能再次提交项目，如有问题，请联系组委会。'
          })
          return
        }
      }
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitting = true;
          let data = this.getDataToServe();
          // if(!data.checkStatus || data.checkStatus == "EMPTY"){
          //   data.checkStatus = "CHECKING"
          // }
          data.checkStatus = "CHECKING"
          userService
            .saveCompetitionProject(data)
            .then(res => {
              if (res.errorNo == 0) {
                this.show = "project";
                
                if(this.timer){this.autoSubmit();}
                this.getCompetitionProjects();
                let _this = this;
                this.$Modal.confirm({
                  title: "参评项目信息提交成功!",
                  okText: "继续添加项目",
                  cancelText: "退出登录",
                  onOk: function() {
                    _this.changeShows("form");
                  },
                  onCancel: function() {
                    _this.$store.commit("setToken", "");
                    _this.$store.commit("setUserInfo", {});
                    window.localStorage.removeItem("login_time");
                    _this.$router.push("/user/login");
                  }
                });
              } else {
                this.$Message.error(res.errorMessage);
              }
              this.submitting = false;
            })
            .catch(() => {
              this.submitting = false;
              this.$Message.error("连接服务器失败，请稍后再试");
            });
        } else {
          this.$Message.error("输入信息有误，请检查必填项是否全部填写");
        }
      });
    },
    // 生成提交信息
    getDataToServe() {
      if (
        this.info.completionDate &&
        typeof this.info.completionDate != "string"
      ) {
        let dt = this.info.completionDate;
        // console.log(dt)
        // dt.setHours(dt.getHours() + 8)
        let sYear = dt.getFullYear() + "";
        let sMonth =
          dt.getMonth() + 1 < 10
            ? "0" + (dt.getMonth() + 1)
            : dt.getMonth() + 1 + "";
        // let sDate = dt.getDate() < 10 ? '0' + dt.getDate() : dt.getDate() + ''
        // let dNew = sYear + '-' + sMonth + '-' + sDate
        let dNew = sYear + "-" + sMonth;
        // this.info.completionDate = dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate()
        this.info.completionDate = dNew;
      }
      let temp = JSON.parse(JSON.stringify(this.info));
      for (let i = 0; i < temp.designPictures.length; i++) {
        temp.designPictures[i].status = null;
      }
      for (let i = 0; i < temp.realPictures.length; i++) {
        temp.realPictures[i].status = null;
      }
      for (let i = 0; i < temp.innovatePictures.length; i++) {
        temp.innovatePictures[i].status = null;
      }
      for (let i = 0; i < temp.methodFile.length; i++) {
        temp.methodFile[i].status = null;
      }
      for (let i = 0; i < temp.standardFile.length; i++) {
        temp.standardFile[i].status = null;
      }
      for (let i = 0; i < temp.patentFile.length; i++) {
        temp.patentFile[i].status = null;
      }
      for (let i = 0; i < temp.achievementsFile.length; i++) {
        temp.achievementsFile[i].status = null;
      }
      for (let i = 0; i < temp.rewardFile.length; i++) {
        temp.rewardFile[i].status = null;
      }
      for (let i = 0; i < temp.evaluateFile.length; i++) {
        temp.evaluateFile[i].status = null;
      }
      for (let i = 0; i < temp.recommendFile.length; i++) {
        temp.recommendFile[i].status = null;
      }
      for (let i = 0; i < temp.prizeFile.length; i++) {
        temp.prizeFile[i].status = null;
      }
      if (temp.acceptanceFile) {
        for (let i = 0; i < temp.acceptanceFile.length; i++) {
          temp.acceptanceFile[i].status = null;
        }
      } else {
        temp.acceptanceFile = [];
      }
      if (temp.descriptionFile) {
        for (let i = 0; i < temp.descriptionFile.length; i++) {
          temp.descriptionFile[i].status = null;
        }
      } else {
        temp.descriptionFile = [];
      }
      if (temp.creativityFile) {
        for (let i = 0; i < temp.creativityFile.length; i++) {
          temp.creativityFile[i].status = null;
        }
      } else {
        temp.creativityFile = [];
      }
      if (temp.industryLeadingFile) {
        for (let i = 0; i < temp.industryLeadingFile.length; i++) {
          temp.industryLeadingFile[i].status = null;
        }
      } else {
        temp.industryLeadingFile = [];
      }
      if (temp.healthyProtectionFile) {
        for (let i = 0; i < temp.healthyProtectionFile.length; i++) {
          temp.healthyProtectionFile[i].status = null;
        }
      } else {
        temp.healthyProtectionFile = [];
      }
      if (temp.benefitsFile) {
        for (let i = 0; i < temp.benefitsFile.length; i++) {
          temp.benefitsFile[i].status = null;
        }
      } else {
        temp.benefitsFile = [];
      }
      if (temp.type == "建筑应用创新综合类" && temp.subType) {
        temp.subType = null;
      }
      return temp;
    },
    handleProgress1(event, file, fileList) {
      this.handleProgress(event, file, fileList, 1);
    },
    handleProgress2(event, file, fileList) {
      this.handleProgress(event, file, fileList, 2);
    },
    handleProgress3(event, file, fileList) {
      this.handleProgress(event, file, fileList, 3);
    },
    handleProgress4(event, file, fileList) {
      this.handleProgress(event, file, fileList, 4);
    },
    handleProgress5(event, file, fileList) {
      this.handleProgress(event, file, fileList, 5);
    },
    handleProgress6(event, file, fileList) {
      this.handleProgress(event, file, fileList, 6);
    },
    handleProgress7(event, file, fileList) {
      this.handleProgress(event, file, fileList, 7);
    },
    handleProgress8(event, file, fileList) {
      this.handleProgress(event, file, fileList, 8);
    },
    handleProgress9(event, file, fileList) {
      this.handleProgress(event, file, fileList, 9);
    },
    handleProgress10(event, file, fileList) {
      this.handleProgress(event, file, fileList, 10);
    },
    handleProgress11(event, file, fileList) {
      this.handleProgress(event, file, fileList, 11);
    },
    handleProgress12(event, file, fileList) {
      this.handleProgress(event, file, fileList, 12);
    },
    handleProgress13(event, file, fileList) {
      this.handleProgress(event, file, fileList, 13);
    },
    handleProgress14(event, file, fileList) {
      this.handleProgress(event, file, fileList, 14);
    },
    handleProgress15(event, file, fileList) {
      this.handleProgress(event, file, fileList, 15);
    },
    handleProgress16(event, file, fileList) {
      this.handleProgress(event, file, fileList, 16);
    },
    handleProgress17(event, file, fileList) {
      this.handleProgress(event, file, fileList, 17);
    },
    handleProgress(event, file, fileList, i) {
      this[`imgList${i}`] = fileList;
      // 调用监听 上传进度 的事件
      event.target.onprogress = event => {
        let uploadPercent = parseFloat(
          (event.loaded / event.total * 100).toFixed(2)
        ); // 保留两位小数，具体根据自己需求做更改
        // 手动设置显示上传进度条 以及上传百分比
        file.showProgress = true;
        file.percentage = uploadPercent;
      };
    },
    handleSuccess1(res, file) {
      this.handleSuccess(res, file, "designPictures");
    },
    handleSuccess2(res, file) {
      this.handleSuccess(res, file, "realPictures");
    },
    handleSuccess3(res, file) {
      this.handleSuccess(res, file, "innovatePictures");
    },
    handleSuccess4(res, file) {
      this.handleSuccess(res, file, "methodFile");
    },
    handleSuccess5(res, file) {
      this.handleSuccess(res, file, "standardFile");
    },
    handleSuccess6(res, file) {
      this.handleSuccess(res, file, "patentFile");
    },
    handleSuccess7(res, file) {
      this.handleSuccess(res, file, "achievementsFile");
    },
    handleSuccess8(res, file) {
      this.handleSuccess(res, file, "rewardFile");
    },
    handleSuccess9(res, file) {
      this.handleSuccess(res, file, "evaluateFile");
    },
    handleSuccess10(res, file) {
      this.handleSuccess(res, file, "recommendFile");
    },
    handleSuccess11(res, file) {
      this.handleSuccess(res, file, "prizeFile");
    },
    handleSuccess12(res, file) {
      this.handleSuccess(res, file, "acceptanceFile");
    },
    handleSuccess13(res, file) {
      this.handleSuccess(res, file, "descriptionFile");
    },
    handleSuccess14(res, file) {
      this.handleSuccess(res, file, "creativityFile");
    },
    handleSuccess15(res, file) {
      this.handleSuccess(res, file, "industryLeadingFile");
    },
    handleSuccess16(res, file) {
      this.handleSuccess(res, file, "healthyProtectionFile");
    },
    handleSuccess17(res, file) {
      this.handleSuccess(res, file, "benefitsFile");
    },
    handleSuccess(res, file, key) {
      if (res.errorNo) {
        this.$Message.error(res.errorMessage);
      } else {
        let data = res.data[0];
        if (data.path) {
          file.path = data.path;
          file.name = data.name;
          file.fileType = data.fileType;
          file.size = data.size;
          this.info[key].push(data);
        }
        this.$forceUpdate();
      }
    },
    handleFormatError(file) {
      this.$Message.error({
        content: `文件${file.name}格式不正确，请上传jpg,jpeg格式的图片。`,
        duration: 5
      });
    },
    handleError(error) {
      this.$Message.error("服务器错误" + error);
    },
    handleMaxSize(file) {
      this.$Message.error({
        content: `文件${file.name}太大，上传图片大小不能超过10M。`,
        duration: 5
      });
    },
    handleMaxSize2(file) {
      this.$Message.error({
        content: `文件${file.name}太大，上传图片大小不能超过20M。`,
        duration: 5
      });
    },
    // handleMaxSize3(file) {
    //   this.$Message.error({
    //     content: `文件${file.name}太大，上传图片大小不能超过5M。`,
    //     duration: 5
    //   });
    // },
    handleBeforeUpload1(file) {
      return this.handleBeforeUpload(file, 1);
    },
    handleBeforeUpload2(file) {
      return this.handleBeforeUpload(file, 2);
    },
    handleBeforeUpload3(file) {
      return this.handleBeforeUpload(file, 3);
    },
    handleBeforeUpload(file, i) {
      let check = this[`imgList${i}`].length < 50;
      if (!check) {
        this.$Message.info({
          content: "最多能上传50张图片",
          duration: 5
        });
      }
      if (file.size == 0) {
        check = false;
      }
      return check;
    },
    handleRemove(file, i) {
      if (i == 5) {
        this.form.photo = "";
      } else {
        const fileList = this.$refs[`imgList${i}`].fileList,
          m = fileList.indexOf(file);
        this.$refs[`imgList${i}`].fileList.splice(m, 1);
        this[`imgList${i}`].splice(m, 1);
      }
    },
    // 删除图片
    removeImg1(i) {
      this.info.designPictures.splice(i, 1);
      this.imgList1.splice(i, 1);
    },
    removeImg2(i) {
      this.info.realPictures.splice(i, 1);
      this.imgList2.splice(i, 1);
    },
    removeImg3(i) {
      this.info.innovatePictures.splice(i, 1);
      this.imgList3.splice(i, 1);
    },
    removeImg4(i) {
      this.info.methodFile.splice(i, 1);
      this.imgList4.splice(i, 1);
    },
    removeImg5(i) {
      this.info.standardFile.splice(i, 1);
      this.imgList5.splice(i, 1);
    },
    removeImg6(i) {
      this.info.patentFile.splice(i, 1);
      this.imgList6.splice(i, 1);
    },
    removeImg7(i) {
      this.info.achievementsFile.splice(i, 1);
      this.imgList7.splice(i, 1);
    },
    removeImg8(i) {
      this.info.rewardFile.splice(i, 1);
      this.imgList8.splice(i, 1);
    },
    removeImg9(i) {
      this.info.evaluateFile.splice(i, 1);
      this.imgList9.splice(i, 1);
    },
    removeImg10(i) {
      this.info.recommendFile.splice(i, 1);
      this.imgList10.splice(i, 1);
    },
    removeImg11(i) {
      this.info.prizeFile.splice(i, 1);
      this.imgList11.splice(i, 1);
    },
    removeImg12(i) {
      this.info.acceptanceFile.splice(i, 1);
      this.imgList12.splice(i, 1);
    },
    removeImg13(i) {
      this.info.descriptionFile.splice(i, 1);
      this.imgList13.splice(i, 1);
    },
    removeImg14(i) {
      this.info.creativityFile.splice(i, 1);
      this.imgList14.splice(i, 1);
    },
    removeImg15(i) {
      this.info.industryLeadingFile.splice(i, 1);
      this.imgList15.splice(i, 1);
    },
    removeImg16(i) {
      this.info.healthyProtectionFile.splice(i, 1);
      this.imgList16.splice(i, 1);
    },
    removeImg17(i) {
      this.info.benefitsFile.splice(i, 1);
      this.imgList17.splice(i, 1);
    }
  },
  destroyed() {
    this.timer && clearInterval(this.timer);
    this.timer = null;
  }
};
</script>

<style lang="scss" scoped>
.projectItem {
  border-radius: 10px;
  border: 1px solid #b7b7b7;
  overflow: hidden;
  margin-bottom: 30px;
  .projectImgWrap {
    position: relative;
    padding-bottom: 60%;
    overflow: hidden;
    .projectImg {
      position: absolute;
      top: 0;
      left: 0;
    }
    .statusWrap {
      position: absolute;
      top: 0;
      right: 0;
      color: #fff;
      .statusText {
        position: absolute;
        top: 15px;
        right: 0;
        font-size: 12px;
        width: 38px;
      }
      .statusBg {
        position: absolute;
        top: 0;
        right: 0;
        border: 35px solid transparent;
        border-top: 35px solid #277a44;
        border-right: 35px solid #277a44;
      }
      .succBg{
        border-top-color: #FFAC00;
        border-right-color: #FFAC00;
      }
      .failBg{
        border-top-color: #ed0606;
        border-right-color: #ed0606;
      }
    }
  }
  .projectTitle {
    font-weight: bold;
    font-size: 16px;
    padding: 15px 0;
  }
  .btnWrap {
    padding: 15px 0 20px;
    .bdRight {
      border-right: 1px solid #979797;
    }
    .myBtn {
      display: inline-block;
      cursor: pointer;
      padding: 5px 0;
    }
  }
  // .editBtn {
  //   display: inline-block;
  //   cursor: pointer;
  //   padding: 15px 0 20px;
  // }
}
.addItem {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 1;
  }
  .addBtnWrap {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 2;
    line-height: 2em;
    color: #bdbdbd;
    margin-top: -29px;

    .addBtn {
      font-size: 18px;
      font-weight: bold;
      cursor: pointer;

      .addIcon {
        font-weight: bold;
        font-size: 24px;
        padding-right: 5px;
      }
    }
  }
}
.mgTop {
  margin-top: 10px;
}
.note {
  color: #b4b4b4;
  font-size: 12px;
  display: inline-block;
  padding-left: 15px;
}
.noteBlock {
  padding-left: 0;
  line-height: 1.2em;
  padding-top: 5px;
}
.imgWrap {
  padding-top: 15px;
  .imgItem {
    position: relative;
    width: 80px;
    height: 80px;
    display: inline-block;
    margin: 0 15px 15px 0;
    background: rgba($color: #000000, $alpha: 0.1);
    vertical-align: top;
    .fileName {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
      padding: 0 4px;
      text-align: center;
      font-size: 12px;
      line-height: 1.3em;
    }
    .img,
    .mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .mask {
      display: none;
      background: rgba($color: #000000, $alpha: 0.5);
      text-align: center;
      .delBtn {
        color: #fff;
        font-size: 20px;
        margin-top: 30px;
        cursor: pointer;
      }
    }
  }
  .imgItem:hover .mask {
    display: inline-block;
  }
}
.nameNote {
  font-size: 14px;
  padding-left: 0;
  padding-top: 6px;
}
.tipNote {
  cursor: pointer;
  margin-left: 15px;
}
.explainWrap {
  background: #f6f8f7;
  padding: 10px 15px;
  font-size: 12px;
  border-radius: 12px;
  color: #979797;
  line-height: 1.5em;
}
</style>