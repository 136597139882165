import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,
    userInfo: {},
  },
  getters: {
    token: state => state.token || window.localStorage.getItem('token') || null,
    userInfo: state => state.userInfo || window.localStorage.getItem('userInfo') ? JSON.parse(window.localStorage.getItem('userInfo')) : {},
  },
  mutations: {
    setToken: (state, data) => {
      state.token = data;
      if (data) {
        window.localStorage.setItem('token', data);
      } else {
        window.localStorage.removeItem('token');
      }
    },
    setUserInfo: (state, data) => {
      state.userInfo = data;
      if (data) {
        window.localStorage.setItem('userInfo', JSON.stringify(data));
      } else {
        window.localStorage.removeItem('userInfo');
      }
    }
  },
  actions: {},
  modules: {}
})