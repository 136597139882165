<template>
<div>
  <!-- PC端 -->
  <div class="pc">
    <div class="wrap" ref="wrap">
      <div class="navWrap">
        <router-link class="navLogo" to="/index"><img class="logo" src="/img/logo.png" alt=""></router-link>
        <div class="navText">
          <router-link class="navItem" to="/introduction">大奖介绍</router-link>
          <router-link class="navItem" to="/notes">参评须知</router-link>
          <router-link class="navItem" to="/rules">评审规则</router-link>
          <router-link class="navItem" to="/expert">评审专家</router-link>
          <router-link class="navItem toLineNavItem" to="/publish">表彰项目公布<span class="navYear">2020-2021</span></router-link>
          <router-link class="navItem" to="/news">新闻动态</router-link>
          <router-link class="navItem" to="/review">往届回顾</router-link>
          <span class="navItem" @click="showContact">联系方式</span>
          <!-- <Input class="searchInput" search /> -->
          <router-link class="signBtn" to="/declare/basic">
            <p class="ch">报名入口</p>
            <p class="en">REGISTRATON</p>
          </router-link>
        </div>
      </div>  
    </div>  
  </div>
  <!-- 移动端 -->
  <div class="mobile">
    <div class="navWrap">
      <router-link class="navLogo" to="/index">
        <img class="logo" src="/img/logo.png" alt="">
        <span>建筑应用创新大奖</span>
      </router-link>
      <div class="btnWrap">
        <Icon v-if="menuShow" @click="menuShow=false" type="md-close" class="menuBtn" />
        <Icon v-else @click="menuShow=true" type="md-menu" class="menuBtn" />
      </div>
      <div class="navText" :class="{'unfoldNav':menuShow}">
        <p class="navItem"><router-link class="navHref" to="/introduction">大奖介绍</router-link></p>
        <p class="navItem"><router-link class="navHref" to="/notes">参评须知</router-link></p>
        <p class="navItem"><router-link class="navHref" to="/rules">评审规则</router-link></p>
        <p class="navItem"><router-link class="navHref" to="/expert">评审专家</router-link></p>
        <!-- <p class="navItem"><router-link class="navHref" to="/publish">表彰项目公布</router-link></p> -->
        <p class="navItem"><router-link class="navHref" to="/declare/basic">报名入口</router-link></p>
        <p class="navItem"><router-link class="navHref" to="/news">新闻动态</router-link></p>
        <p class="navItem"><router-link class="navHref" to="/review">往届回顾</router-link></p>
        <p class="navItem"><span class="navHref" @click="showContact">联系方式</span></p>
      </div>
    </div>
  </div>
  <router-view></router-view>
  <!-- PC端 -->
  <div class="pc">
    <div class="footer">
      <Row>
        <Col span="6" offset="18" class="footerInfo">
          <p><a href="http://beian.miit.gov.cn/" target="_blank" class="beian">京ICP备17011295号-4</a></p>
          <p>建筑应用创新大奖 版权所有</p>
        </Col>
      </Row>
    </div>
  </div>
  <!-- 移动端 -->
  <div id="contact" class="mobile">
    <div class="contact">
      <p class="title">联系我们</p> 
      <p><span class="label">评审组委会</span>010-88083947/2319</p>
      <p><span class="label">组委会邮箱</span>cbmea2006@126.com</p>
      <p><span class="label">组委会地址</span>北京市海淀区三里河路甲11号 中国建材大厦C座3层</p>
      <p><span class="label">联&nbsp;&nbsp;&nbsp;系&nbsp;&nbsp;&nbsp;人</span>13901127933(刘秀明)</p>
      <p><span class="label">微信公众号</span>国家建筑应用创新大奖</p>
      <p class="center"><img class="wxCode" src="/img/wxCode.jpg" alt=""></p>
    </div>
    <div class="footer center">
      <p><a href="http://beian.miit.gov.cn/" target="_blank" class="beian">京ICP备17011295号-4</a></p>
      <p>建筑应用创新大奖 版权所有</p>
    </div>
  </div>
</div>
</template>
<script>
export default {
  data(){
    return{
      menuShow: false
    }
  },
  mounted(){
    let width = document.documentElement.clientWidth
    if(width>=992){
      this.setWrapStyle(width)
    }
  },
  methods:{
    showContact(){
      document.getElementById('contact').scrollIntoView()
      this.menuShow = false
    },
    // 设置导航容器的padding-bottom
    setWrapStyle(w){
      if(this.$route.name=='Index' || this.$route.name=='Home'){
        let pd = document.documentElement.clientHeight / w * 100
        this.$refs.wrap.style.paddingBottom = pd + '%'
      }else{
        this.$refs.wrap.style.paddingBottom = '48.38%'
      }
    }
  },
  watch: {
    '$route': function(){
      let width = document.documentElement.clientWidth
      if(width>=992){
        this.setWrapStyle(width)
      }else{
        this.menuShow = false
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.pc{
  .wrap{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding-bottom: 48.38%;
    .navWrap{
      position: absolute;
      bottom: 0;
      left: 100px;
      right: 0;
      background: #fff;
      border-top: 1px solid #277A44;
      border-bottom: 1px solid #707070;
      border-top-left-radius: 100px;
      border-bottom-left-radius: 100px;
      height: 80px;
      padding-right: 150px;
      z-index: 9;
      overflow: hidden;
    
      .logo{
        padding: 0;
        margin: 0;
        width: 80px;
        vertical-align: middle;
        margin-right: 30px;
        margin-top: 0;
        animation: fadeInUp 1s ease-in-out both;
      }
      @keyframes fadeInUp {
        0% {
          opacity: 0;
          transform:translateY(100%);
        }
        100% {
          opacity: 1;
          transform: translateY(0);
        }
      }
      .navText{
        position: absolute;
        left: 110px;
        right: 0;
        top: 0;
        bottom: 0;
        animation: fadeInRight 1s ease-in-out both;
      }
      @keyframes fadeInRight {
        0% {
          opacity: 0;
          transform:translateX(100%);
        }
        100% {
          opacity: 1;
          transform: translateX(0);
        }
      }
      .navItem{
        display: inline-block;
        line-height: 80px;
        padding: 0 15px;
        color: #646262;
        font-weight: bold;
        font-size: 16px;
        margin-top: -1px;
        cursor: pointer;
      }
      .toLineNavItem{
        position: relative;
        color: #bc895c;
        .navYear{
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          text-align: center;
          line-height: 42px;
          font-size: 12px;
          color: #e5d381;
        }
      }
      .router-link-active {
        color: #fff;
        background: #277A44;
      }
      .navLogo.router-link-active{
        background: #fff;
      }
      .searchInput{
        width: auto;
        margin-left: 15px;
        display: inline-block;
      }
      .signBtn{
        position: absolute;
        width: 130px;
        height: 79px;
        right: 0;
        top: -1px;
        bottom: 0;
        color: #fff;
        background: #277A44;
        text-align: center;
        .ch{
          font-size: 22px;
          padding-top: 12px;
        }
        .en{
          font-size: 12px;
        }
      }
    }
  }
  .footer{
    border-top: 1px solid #E9E9E9;
    padding: 0 10%;
    .footerInfo{
      padding: 10px 15px;
      line-height: 1.8em;
      border-left: 1px solid #E9E9E9;
      .beian{
        color: #3F4641;
      }
    }
  }
}
.mobile{
  .navWrap{
    position: fixed;
    top: 0;
    height: 60px;
    left: 0;
    right: 0;
    background: rgba($color: #fff, $alpha: .6);
    padding: 10px 15px;
    z-index: 999;
    .navLogo{
      font-size: 20px;
      color: #277A44;
      .logo{
        width: 40px;
        height: 40px;
        vertical-align: middle;
        margin-right: 15px;
      }
    }
    .menuBtn{
      position: fixed;
      top: 10px;
      right: 15px;
      font-size: 40px;
      color: #277A44;
    }
    .navText{
      position: fixed;
      left: 0;
      right: 0;
      top: 60px;
      bottom: 100%;
      background: #fff;
      padding: 0 15px;
      overflow: hidden;
      text-align: center;
    }
    .navItem{
      border-bottom: 1px solid #CECECE;
    }
    .navItem:last-child{
      border-bottom: none;
    }
    .navHref{
      display: inline-block;
      padding: 20px 0;
      color: #464646;
    }
    .unfoldNav{
      bottom: 0;
      height: auto;
    }
  }
  .contact{
    padding: 20px 15px;
    line-height: 1.8em;
    .title{
      padding: 20px 0 10px;
      font-size: 24px;
    }
    .label{
      display: inline-block;
      width: 80px;
      font-weight: bold;
    }
    .wxCode{
      max-width: 70%;
    }
  }
  .footer{
    background: #F4F4F4;
    padding: 30px 0;
    .beian{
      color: #3F4641;
    }
  }
}
</style>