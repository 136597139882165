<template>
  <div class="declare">
    <div class="titleWrap">
      <div class="topBtn">
        <router-link class="website" to="/index" target="_blank">大奖官网</router-link>
        <span @click="logout" class="logout">退出登录</span>
      </div>
      <p class="title">建筑应用创新大奖参评项目申报系统</p>
    </div>
    <div class="content">
      <div class="navLeft">
        <p><router-link class="nav" to="/declare/basic">参评单位信息</router-link></p>
        <p><router-link class="nav" to="/declare/project">参评项目信息</router-link></p>
      </div>
      <div class="contentRight"><router-view></router-view></div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    logout() {
      this.$store.commit("setToken", "");
      this.$store.commit("setUserInfo", {});
      window.localStorage.removeItem("login_time");
      this.$router.push("/user/login");
    }
  }
};
</script>

<style lang="scss" scoped>
.declare {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
  background: #f3f3f3;
  padding-bottom: 100px;

  .topBtn {
    position: absolute;
    right: 200px;
    top: 25px;
    z-index: 99;

    .website {
      color: #277a44;
      border: 1px solid #277a44;
      border-radius: 5px;
      padding: 5px 10px;
      margin-right: 15px;

      &:hover {
        color: #fff;
        background: #277a44;
      }
    }
    .logout {
      color: #277a44;
      cursor: pointer;
      padding-right: 14px;
      background: url(/img/logout.png) no-repeat right;
      background-size: 12px;
    }
  }
  .titleWrap {
    min-width: 1200px;
    position: relative;
    padding-bottom: 15.93%;
    background: url(/img/bg2.png) no-repeat;
    background-size: cover;
    .title {
      position: absolute;
      font-size: 24px;
      font-weight: bold;
      color: #277a44;
      font-family: Source Han Sans CN;
      // transform: translate(-50%, -50%);
      margin: -18px 0 0 -192px;
      left: 50%;
      top: 40%;
    }
  }
  .content {
    min-width: 1200px;
    position: relative;
    padding: 0 200px 0 230px;
    top: -10%;
    .navLeft {
      position: absolute;
      width: 130px;
      left: 100px;
      top: 5px;
      .nav {
        color: #3f4641;
        padding: 5px 0;
        display: inline-block;
        margin: 5px 0;
      }
      .router-link-active {
        color: #277a44;
        position: relative;
      }
      .router-link-active::after {
        content: "";
        position: absolute;
        right: -8px;
        top: 8px;
        height: 15px;
        border-right: 2px solid #277a44;
      }
    }
    .contentRight {
      padding: 30px 30px 60px;
      background: #fff;
      border-radius: 12px;
      box-shadow: 0 0 16px rgba($color: #fff, $alpha: 0.4);
    }
  }
  @media screen and (max-width: 992px) {
    .topBtn {
      right: 30px;
    }
    .content {
      top: 0;
    }
  }
}
</style>