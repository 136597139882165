import {
  imgBaseUrl,
  fileBaseUrl
} from '@/config/env'

export default {
  methods: {
    // 是否登录
    gIsLogin() {
      let loginTime = window.localStorage.getItem('login_time'),
        now = new Date().getTime();
      return loginTime && Number(loginTime) + 86400000 > now;
    },
    //图片居中显示
    gCenterImg(e, f) { //f: 1-按短边显示， 2-按长边显示
      let img = e.currentTarget,
        parent = img.parentNode,
        iw = img.width,
        ih = img.height,
        imgWH = iw / ih,
        pw = parent.clientWidth,
        ph = parent.clientHeight,
        parentWH = pw / ph,
        flag = f == 2 ? parentWH / imgWH : imgWH / parentWH;
      if (flag < 1) {
        img.style.height = 'auto';
        img.style.marginLeft = 0;
        img.style.width = '100%';
        img.style.marginTop = (ph - pw / imgWH) / 2 + 'px';
      } else {
        img.style.width = 'auto';
        img.style.marginTop = 0;
        img.style.height = '100%';
        img.style.marginLeft = (pw - ph * imgWH) / 2 + 'px';
      }
    },
    getImageUrl(path, style) {
      let url = imgBaseUrl + path;
      // if (!path) {
      //   path = 'IMG/123.jpg';
      // }

      if (!style || style == 'origin') {
        return url;
      } else {
        return url + "/" + style;
      }
    },
    getFileUrl(path){
      return fileBaseUrl+path;
    },
    // 获取html字符串中的汉字
    getHtmlText(val) {
      // let reg = /[\u4e00-\u9fa5]/g;
      // let strs = val.match(reg);
      // return strs.join("");
      if(val){
        return val.replace(/<img.*?>/g ,'');
      }else{
        return '';
      }
    }
  }
}