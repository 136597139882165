<template>
  <div class="news">
    <div class="bannerWrap">
      <img class="banner" src="/img/banner.jpg" alt="">
    </div>
    <div class="section">
      <Row>
        <Col :lg="18" :xs="24" class="leftInfo">
          <div class="info">
            <p class="mobile title center">新闻动态</p>
            <Row :gutter="15" class="mgBtm" type="flex" align="middle">
              <Col :lg="{span:12,push:12}" :xs="24">
              <div>
                <img class="cover" :src="getImageUrl(info.cover)" alt="">
              </div>
              </Col>
              <Col :lg="{span:12,pull:12}" :xs="24">
                <p class="infoTime">{{getFormatDate(info.startDate).month}}{{getFormatDate(info.startDate).day}},{{getFormatDate(info.startDate).year}}</p>
                <p class="neRow">
                  <a v-if="info.type=='媒体报道'" class="name" :href="info.url" target="_blank" rel="noopener noreferrer">{{info.title}}</a>
                  <router-link v-else class="name" :to="`/news/${info.id}`">{{info.title}}</router-link>
                </p>
                <p class="content threeRows" v-html="getHtmlText(info.content)"></p>
                <p>
                  <a v-if="info.type=='媒体报道'" class="newsLink pc" :href="info.url" target="_blank" rel="noopener noreferrer">查看详情</a>
                  <router-link v-else class="newsLink pc" :to="`/news/${info.id}`">查看详情</router-link>
                </p>
              </Col>
            </Row>
            <div class="newsItem" v-for="l in list" :key="l.id">
              <a v-if="l.type=='媒体报道'" :href="l.url" target="_blank">
                <div class="timeWrap">
                  <p class="day">{{getFormatDate(l.startDate).day}}</p>
                  <p>{{getFormatDate(l.startDate).month}}</p>
                  <p>{{getFormatDate(l.startDate).year}}</p>
                </div>
                <p class="name oneRow">{{l.title}}</p>
                <p class="oneRow colorText">新闻类型：{{l.type}}</p>
                <!-- <p class="oneRow colorText">发布者：{{l.author}}&nbsp;&nbsp;&nbsp;&nbsp;浏览次数：{{l.views}}</p> -->
                <p class="twoRows smallLine urlRows">url</p>
              </a>
              <router-link v-else :to="`/news/${l.id}`">
                <div class="timeWrap">
                  <p class="day">{{getFormatDate(l.startDate).day}}</p>
                  <p>{{getFormatDate(l.startDate).month}}</p>
                  <p>{{getFormatDate(l.startDate).year}}</p>
                </div>
                <p class="name oneRow">{{l.title}}</p>
                <p class="oneRow colorText">新闻类型：{{l.type}}</p>
                <!-- <p class="oneRow colorText">发布者：{{l.author}}&nbsp;&nbsp;&nbsp;&nbsp;浏览次数：{{l.views}}</p> -->
                <p class="twoRows smallLine" v-html="getHtmlText(l.content)"></p>
              </router-link>
            </div>
            <Button v-if="total>list.length" :loading="loading" @click="loadList" class="newsLink moreBtn" long>查看更多</Button>
          </div>
        </Col>
        <Col span="6" class="pc">
          <img class="time" src="/img/introduction/time.png" alt="">
          <div id="contact" class="contact">
            <p class="title">联系我们</p> 
            <div class="clearfix">
              <div class="label">评审组委会：</div>
              <div class="info">010-88083947/2319</div>  
            </div>
            <div class="clearfix">
              <div class="label">组委会邮箱：</div>
              <div class="info">cbmea2006@126.com</div>  
            </div>
            <div class="clearfix">
              <div class="label">组委会地址：</div>
              <div class="info">北京市海淀区三里河路甲11号 中国建材大厦C座3层</div>  
            </div>
            <div class="clearfix">
              <div class="label">联&nbsp;&nbsp;&nbsp;系&nbsp;&nbsp;&nbsp;人：</div>
              <div class="info">13901127933(刘秀明)</div>  
            </div>
            <div class="clearfix">
              <div class="label">微信公众号：</div>
              <div class="info">国家建筑应用创新大奖</div>  
            </div>
            <p class="center"><img class="wxCode" src="/img/wxCode.jpg" alt=""></p>
          </div>
        </Col>
      </Row>
    </div>
  </div>
</template>

<script>
import NewsService from "@/api/news";
export default {
  data(){
    return{
      info: {},
      currentPage: 1,
      list: [],
      total: 0,
      loading: false,
    }
  },
  mounted(){    
    this.getList()
  },
  methods:{
    // 拆分日期
    getFormatDate(date){
      let monthList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
      let temp = new Date(date)
      let year = temp.getFullYear(), month = monthList[temp.getMonth()], day = temp.getDate()
      return {year, month, day}
    },
    // 查看更多
    loadList(){
      this.currentPage += 1
      this.getList();
    },
    // 获取列表
    getList() {
      this.loading = true;
      let condition = {
        page: this.currentPage - 1,
        size: 10,
        sort: "isTop,desc",
        'isHide.eq': 0,
      };
      NewsService.getNews(condition)
        .then(res => {
          if (res.errorNo == 0) {
            if(this.currentPage==1){
              this.total = res.totalSize
              // this.info = res.data[0] || {}
              this.list = res.data;
            }else{
              this.list = this.list.concat(res.data);
            }
          } else {
            this.$Message.error(res.errorMessage);
          }
          this.loading = false;
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
          this.loading = false;
        });
        
      let condition1 = {
        page: 1,
        size: 1,
        sort: "isTop,desc",
        'isHide.eq': 0,
        "cover.startWith": "IMG"
      };
      NewsService.getNews(condition1)
        .then(res => {
          if (res.errorNo == 0) {
            this.info = res.data[0] || {}
          } else {
            this.$Message.error(res.errorMessage);
          }
        })
        .catch(() => {
          // this.$Message.error("连接服务器失败，请稍后再试");
        });
    }
  }
}
</script>

<style lang="scss" scoped>
.news{
  .section{
    width: 80%;
    margin: auto;
    color: #8C8C8C;
    line-height: 1.8em;
    .leftInfo{
      border-right: 1px solid #E9E9E9;
      .info{
        padding: 50px 15px;
        .mgBtm{
          margin-bottom: 20px;
        }
        .cover{
          width: 100%;
        }
        .infoTime{
          color: #4AB56F;
          font-size: 20px;
          padding-bottom: 10px;
        }
        .name{
          font-size: 16px;
          color: #4C5C4E;
          padding-bottom: 5px;

          &:hover{
            color: #237B40;
          }
        }
        .content{
          min-height: 5.4em;
        }
        .newsLink{
          display: block;
          background: #E6EBE8;
          color: #237B40;
          padding: 10px 0;
          text-align: center;
          margin-top: 10px;

          &:hover{
            background: #237B40;
            color: #FFFFFF;
          }
        }
        .moreBtn{
          height: auto;
          margin-top: 20px;
          border-radius: 0;
          border: none;
        }
        .newsItem{
          position: relative;
          border-top: 1px solid #E9E9E9;
          padding: 20px 10px 20px 110px;
          &:hover{
            background: #E6EBE8;
          }
          .timeWrap{
              width: 90px;
              height: 90px;
              position: absolute;
              left: 0;
              top: 20px;
              color: #4C5C4E;
              background: #E6EBE8;
              vertical-align: bottom;
              line-height: 1.3em;
              padding: 15px 10px 8px;
            .day{
              font-size: 30px;
              color: #287743;
              padding-bottom: 5px;
            }
          }
          .urlRows{
            visibility: hidden;
          }
          .name{
            padding-bottom: 2px;
          }
          .colorText{
            color: #8C8C8C;
          }
          .smallLine{
            line-height: 1.5em;
            color: #8C8C8C;
          }
        }
      }
    }
    .time{
      width: 100%;
      padding-top: 50px;
    }
    .contact{
      padding: 20px;
      line-height: 1.8em;
      .title{
        font-size: 18px;
        padding-bottom: 10px;
      }
    }
  }
  @media screen and (max-width: 992px) {
    .section{
      width: 100%;
      .leftInfo{
        border-right: none;
        .info{
          padding-bottom: 0;
          .title{
            font-size: 24px;
            padding-bottom: 20px;
            font-weight: bold;
            color: #4D4D4D;
          }
        }
      }
    }
  }
}
</style>