<template>
  <div class="userFrom">
    <p class="center"><span class="wayText wayActive">重置密码</span></p>
    <Form key="resetPwd" ref="form" :model="form" :rules="rules" hide-required-mark :label-width="70" label-position="left">
      <div class="inputWrap">
        <FormItem label="手机号" prop="mobilePhone">
          <Input v-model="form.mobilePhone" :border="false"></Input>    
        </FormItem>  
      </div>
      <div class="msgWrap">
        <div class="inputWrap">
          <FormItem label="验证码" prop="captcha">
            <Input v-model="form.captcha" :border="false"></Input>    
          </FormItem>
        </div>
        <Button class="defaultBtn msgBtn" shape="circle" :disabled="codeBtnText!='获取验证码'" @click="sendMsgCode">{{codeBtnText}}</Button>  
      </div>
      <div class="inputWrap">
        <FormItem label="新密码" prop="password">
          <Input type="password" placeholder="8-16位字符，包含字母和数字" v-model="form.password" :border="false"></Input>    
        </FormItem>
      </div>
      <div class="inputWrap">
        <FormItem label="确认密码" prop="confirmPassword">
          <Input type="password" placeholder="8-16位字符，包含字母和数字" v-model="form.confirmPassword" :border="false"></Input>    
        </FormItem>
      </div>
      <Button class="defaultBtn" long shape="circle" :loading="submitting" @click="submit">确认重置</Button>
    </Form>
    <p class="center"><router-link class="colorGreen" :to="`/user/login?fromUrl=${$route.query.fromUrl}`">返回登录</router-link></p>
  </div>
</template>

<script>
import userService from "@/api/user";
export default {
  data() {
    return {
      form: {},
      rules: {
        mobilePhone: [
          {
            required: true,
            pattern: /^1[3456789]\d{9}$/,
            message: "请正确输入手机号码",
            trigger: "blur"
          }
        ],
        captcha: [
          { required: true, message: "请输入短信验证码", trigger: "blur" }
        ],
        password: [
          {
            required: true,
            message: "密码需8-16位字符，包含字母和数字",
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/
            // pattern: /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?![?~!=,#&@$%.+*()|{}"<>'\-:;^_`]+$)[?~!=,#&@$%.+*()|{}"<>'\-:;^_`0-9A-Za-z]{8,16}$/
          }
        ],
        confirmPassword: [
          {
            required: true,
            message: "需与新密码相同",
            validator: (rule, value) => {
              if (!value || value != this.form.password) {
                return false;
              }
              return true;
            }
          }
        ]
      },
      submitting: false,
      codeBtnText: "获取验证码"
    };
  },
  methods: {
    // 获取验证码
    sendMsgCode() {
      this.$refs.form.validateField("mobilePhone", valid => {
        if (valid) {
          this.$Message.error("请输入正确的手机号");
        } else {
          let condition = {
            smsType: "RESET_PASSWORD",
            mobilePhone: this.form.mobilePhone
          };
          let timer = null;
          userService.sendCaptcha(condition).then(res => {
            if (res.errorNo == 0) {
              this.$Message.success("验证码发送成功");
              let time = 60,
                _this = this;
              timer = setInterval(function() {
                time--;
                _this.codeBtnText = `获取验证码${time}`;
                if (time == 0) {
                  clearInterval(timer);
                  _this.codeBtnText = "获取验证码";
                }
              }, 1000);
            } else {
              this.$Message.error(res.errorMessage);
            }
          });
          this.$once("hook:beforeDestroy", () => {
            clearInterval(timer);
            this.codeBtnText = "获取验证码";
          });
        }
      });
    },
    // 注册
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitting = true;
          let condition = {
            mobilePhone: this.form.mobilePhone,
            code: this.form.captcha,
            newPassword: this.form.password
          };
          userService
            .resetPwd(condition)
            .then(res => {
              if (res.errorNo == 0) {
                this.$Message.success("密码重置成功！");
                this.$router.push(
                  `/user/login?fromUrl=${this.$route.query.fromUrl}`
                );
              } else {
                this.$Message.error(res.errorMessage);
              }
              this.submitting = false;
            })
            .catch(() => {
              this.submitting = false;
              this.$Message.error("连接服务器失败，请稍后再试");
            });
        } else {
          this.$Message.error("输入信息有误，请检查必填项是否全部填写");
        }
      });
    }
  }
};
</script>
<style lang="scss">
.userFrom {
  .wayText {
    display: inline-block;
    color: #3f4641;
    cursor: pointer;
    margin-bottom: 30px;
    font-size: 16px;
  }
  .wayActive {
    cursor: default;
    font-weight: bold;
    border-bottom: 2px solid #277a44;
  }
  .inputWrap {
    background: #eaeaea;
    padding: 3px 15px;
    border-radius: 100px;
    margin-bottom: 24px;

    .ivu-form-item {
      margin-bottom: 0;
      .ivu-input {
        background: transparent;
      }
    }
    .ivu-form-item-error .ivu-input {
      border: transparent;
    }
  }
  .msgWrap {
    position: relative;
    padding-right: 130px;

    .msgBtn {
      position: absolute;
      right: 0;
      top: 0;
      margin: 0;
      padding: 0 20px;
    }
  }
  .defaultBtn {
    margin: 10px 0;
    height: 40px;
  }
  .defaultBtn[disabled] {
    background: rgba(#277a44, 0.8);
  }
}
</style>