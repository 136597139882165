<template>
<div>
  <div class="pc">
    <full-page v-if="isPc" :options="options" ref="fullpage" id="fullpage">
      <div class="section homeBannerWrap">
        <img class="banner" src="/img/banner.jpg" @load="gCenterImg($event, 1)" alt="">
      </div>
      <div class="section pageItem">
        <div class="news">
          <Row :gutter="30">
            <Col :lg="12" :xs="24" v-if="newsList1.length>0">
              <p class="title oneRow">
                <router-link class="title" :to="`/news/${newsList1[0].id}`">{{newsList1[0].title}}</router-link>
              </p>
              <p class="threeRows" v-html="getHtmlText(newsList1[0].content)"></p>
            </Col>
            <Col :lg="12" :xs="24">
              <p class="tabWrap">
                <span class="tabItem" :class="{'tabActive': tabIndex==1}" @click="changeTab(1)">大奖头条</span>
                <span class="tabItem" :class="{'tabActive': tabIndex==2}" @click="changeTab(2)">官方动态</span>
                <span class="tabItem" :class="{'tabActive': tabIndex==3}" @click="changeTab(3)">媒体报道</span>
              </p>
              <p v-if="newsList.length==0">暂无此类新闻</p>
              <template v-else>
                <p class="oneRow" v-for="l in newsList" :key="l.id">
                  <a v-if="l.type=='媒体报道'" class="newsName" :href="l.url" target="_blank" rel="noopener noreferrer">{{getShortDate(l.startDate)}} {{l.title}}</a>
                  <router-link v-else class="newsName" :to="`/news/${l.id}`">{{getShortDate(l.startDate)}} {{l.title}}</router-link>
                </p>
              </template>
              <p class="right"><router-link class="colorGreen" to="/news">查看更多</router-link></p>
            </Col>
          </Row>
          <div class="projectNum">
            <p class="colorDark">已收集参评项目</p>
            <p class="colorGreen bigNum">563 <span class="unit">个</span></p>
            <p>截至 2021-08-05</p>
          </div>
        </div>
        <Row class="introWrap">
          <Col :sm="8" :xs="24">
            <div class="item item1">
              <div class="info">
                <p>建筑应用创新大奖是什么</p>
              </div>
              <div class="moreInfo myBar">
                <p class="question">建筑应用创新大奖是什么</p>
                <p class="answer">“建筑应用创新大奖”是经中共中央办公厅、国务院办公厅审核批准的全国评比达标表彰保留项目（名列中央国家机关保留项目第380项，见《全国评比达标表彰保留项目目录》全国评比达标表彰工作协调小组办公室2015年8月颁布），“鲁班奖”“詹天佑奖”“国家优质工程奖”“广厦奖”同在此名单（分别名列第212、210、176、213项），“建筑应用创新大奖” 旨在落实国家“十四五” 发展规划，坚持创新驱动发展，全面塑造发展新趋势，提升企业创新发展能力。是加快建筑施工、人居环境创新升级、提高建材绿色发展的重要推手；是促进建筑行业、建材行业科技创新发展、融合发展，加快创新成果应用展示和转化的重要平台。</p>
              </div>
            </div>
          </Col>
          <Col :sm="8" :xs="24">
            <div class="item item2">
              <div class="info">
                <p>组织机构</p>
              </div>
              <div class="moreInfo">
                <p class="question">组织机构</p>
                <div class="answer">
                  <p class="bold">主办单位</p>
                  <p>国家建筑材料展贸中心</p>
                  <p class="bold">指导单位</p>
                  <p>中国建筑材料工业规划研究院</p>
                  <p>中国建筑标准设计研究院</p>
                  <p>清华大学清控人居集团</p>
                  <p>中国土木工程学会总工程师委员会</p>
                </div>
              </div>
            </div>
          </Col>
          <Col :sm="8" :xs="24">
            <div class="item item3">
              <div class="info">
                <p>评审机构</p>
              </div>
              <div class="moreInfo">
                <p class="question">评审机构</p>
                <div class="answer">
                  <img class="chart" src="/img/index/chart.jpg" alt="">
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div class="section pageItem">
        <p class="pageTitle"><span class="title">视频中心</span></p>
        <video class="video" :src="getFileUrl('video/v1.mp4')" poster="/img/index/videoCover.jpg" controls="controls">
          您的浏览器不支持 video 标签。
        </video>
      </div>
      <div class="section pageItem">
        <p class="pageTitle"><span class="title">评审专家</span></p>
        <div class="expertWrap">
          <Carousel loop dots="none" arrow="always">
            <CarouselItem v-for="(e, i) in expertList" :key='i'>
              <Row :gutter="15">
                <Col :lg="4" :sm="6" :xs="12" v-for="n in e" :key="n.id">
                  <div class="expertItem">
                    <img class="expertImg" :src="getImageUrl(n.avatar, 'square-small')" alt="">
                    <p class="expertName oneRow">{{n.trueName}}</p>
                    <p class="expertJob twoRows">{{n.company}}</p>
                    <p class="expertJob twoRows">{{n.job}}</p>
                  </div>
                </Col>
              </Row>
            </CarouselItem>
          </Carousel>
        </div>
      </div>
      <div class="section pageItem">
        <p class="pageTitle"><span class="title">参评对象</span></p>
        <Row :gutter="15">
          <Col :lg="3" :xs="6">
            <div class="objItem objItem1">制造企业</div>
          </Col>
          <Col :lg="3" :xs="6">
            <div class="objItem objItem2">设计企业</div>
          </Col>
          <Col :lg="3" :xs="6">
            <div class="objItem objItem3">施工企业</div>
          </Col>
          <Col :lg="3" :xs="6">
            <div class="objItem objItem4">工程项目</div>
          </Col>
          <Col :lg="3" :xs="6">
            <div class="objItem objItem5">地产公司</div>
          </Col>
          <Col :lg="3" :xs="6">
            <div class="objItem objItem6">科研院所</div>
          </Col>
          <Col :lg="3" :xs="6">
            <div class="objItem objItem7">服务机构</div>
          </Col>
          <Col :lg="3" :xs="6">
            <div class="objItem objItem8">个人参赛</div>
          </Col>
        </Row>
      </div>
      <div class="section pageItem">
        <p class="pageTitle"><span class="title">参评类别</span></p>
        <div class="typeWrap">
          <Row :gutter="15">
            <Col :md="12" :xs="24">
              <router-link to="/notes?id=1">
                <div class="typeItem typeItem1">
                  <p class="typeText">在建筑材料、部品部件、设备设施、设计、施工工艺、新技术与新施工设备、智能化、装配式建筑、建筑节能、既有建筑改造等多个方面的具有创新的工程应用项目。</p>
                  <div class="typeIconWrap">
                    <div class="typeIcon">建筑应用创新综合类</div>
                  </div>
                </div>
              </router-link>
            </Col>
            <Col :md="12" :xs="24">
              <router-link to="/notes?id=2">
                <div class="typeItem typeItem2">
                  <div class="typeText">
                    <p>建筑材料应用创新类</p>
                    <p>装配式建筑应用创新类</p>
                    <p>建筑部品部件应用创新类</p>
                    <p>设备设施应用创新类</p>
                    <p>其它应用创新类</p>  
                  </div>
                  <div class="typeIconWrap">
                    <div class="typeIcon">建筑应用创新单项类</div>
                  </div>
                </div>  
              </router-link>
            </Col>
          </Row>
        </div>
      </div>
      <div class="section pageItem">
        <p class="pageTitle"><span class="title">参评要求</span></p>
        <div class="ruleWrap">
          <Row>
            <div class="myCol ivu-col">
              <div class="ruleItem">
                <div class="ruleIcon">符合规范</div>
                <div class="moreInfo">
                  <p>符合国家相关的规范、标准及规定，满足国家工程建设标准强制性条文。贯彻“适用、经济、绿色、美观”的建筑方针，突出建筑使用功能以及节能、节水、节地、节材和环保。</p>
                </div>
              </div>
            </div>
            <div class="myCol ivu-col">
              <div class="ruleItem">
                <div class="ruleIcon">工程应用</div>
                <div class="moreInfo">
                  <p>申报项目须应用于工程中，注重新技术和新材料创新应用。</p>
                </div>
              </div>
            </div>
            <div class="myCol ivu-col">
              <div class="ruleItem">
                <div class="ruleIcon">社会责任</div>
                <div class="moreInfo">
                  <p>履行社会责任，具有显著的社会、经济和环境效益。</p>
                </div>
              </div>
            </div>
            <div class="myCol ivu-col">
              <div class="ruleItem">
                <div class="ruleIcon">数量限制</div>
                <div class="moreInfo">
                  <p>每个单位或个人申报同类产品不超过二个。</p>
                </div>
              </div>
            </div>
            <div class="myCol ivu-col">
              <div class="ruleItem">
                <div class="ruleIcon">体现创新</div>
                <div class="moreInfo">
                  <p>申报项目应充分体现在建筑应用创新方面的特点，在技术创新（材料、工艺、设计、管理）、绿色健康、行业引领、社会责任等方面能带领行业发展，具有良好的业内评价和市场认知度。</p>
                </div>
              </div>
            </div>
          </Row>
        </div>
      </div>
      <div class="section pageItem">
        <Row>
          <Col span="18">
            <div class="timeWrap">
              <p class="timeTitle">时间安排</p>
              <Row class="timeBorder">
                <Col span="8" :class="{'timeActive': timeStep==1}">
                  <p>申报阶段</p>
                  <p>2020年12月-2021年07月</p>
                </Col>
                <Col span="8" :class="{'timeActive': timeStep==2}">
                  <p>评审阶段</p>
                  <p>2021年08月-2021年09月</p>
                </Col>
                <Col span="8" :class="{'timeActive': timeStep==3}">
                  <p>奖项发布</p>
                  <p>2021年11月<span class="colorLight">(依照国家疫情相关政策确定时间)</span></p>
                </Col>
              </Row>
            </div>
            <div id="contact" class="contactWrap">
              <p class="contactTitle">联系我们</p>
              <Row>
                <Col span="10">
                  <p><Icon type="ios-call-outline" class="contactIcon" />评审组委会：010-88083947/2319</p>
                </Col>
                <Col span="14">
                  <p><Icon type="ios-phone-portrait" class="contactIcon" />联系人：13901127933(刘秀明)</p>
                </Col>
                <Col span="10">
                  <p><Icon type="ios-mail-outline" class="contactIcon" />组委会邮箱：cbmea2006@126.com</p>
                </Col>
                <Col span="14">
                  <p><Icon type="ios-chatbubbles-outline" class="contactIcon" />微信公众号：国家建筑应用创新大奖</p>
                </Col>
                <Col span="24">
                  <p><Icon type="ios-send-outline" class="contactIcon" />组委会地址：北京市海淀区三里河路甲11号 中国建材大厦C座3层</p>
                </Col>
                <Col span="24">
                  <p class="center"><img class="wxCode" src="/img/wxCode.jpg" alt=""></p>
                </Col>
              </Row>  
            </div>
          </Col>
          <Col span="6">
            <a href="http://www.cdca21.com" target="_blank">
              <img class="adImg" src="/img/index/ad.jpg" alt="">
            </a>
          </Col>
        </Row>
      </div>
    </full-page>
  </div>
  <div class="mobile">
    <div class="mbannerWrap">
      <img src="/img/banner.jpg" alt="" class="bannerImg">
    </div>
    <div class="awardInfo center info1">
      <div class="info">
        <p class="question">建筑应用创新大奖是什么</p>
        <p>“建筑应用创新大奖”是经中共中央办公厅、国务院办公厅审核批准的全国评比达标表彰保留项目（名列中央国家机关保留项目第380项，见《全国评比达标表彰保留项目目录》全国评比达标表彰工作协调小组办公室2015年8月颁布），“鲁班奖”“詹天佑奖”“国家优质工程奖”“广厦奖”同在此名单（分别名列第212、210、176、213项），“建筑应用创新大奖” 旨在落实国家“十四五” 发展规划，坚持创新驱动发展，全面塑造发展新趋势，提升企业创新发展能力。是加快建筑施工、人居环境创新升级、提高建材绿色发展的重要推手；是促进建筑行业、建材行业科技创新发展、融合发展，加快创新成果应用展示和转化的重要平台。</p>
      </div>
    </div>
    <div class="awardInfo center info2">
      <div class="info">
        <p class="question">组织机构</p>
        <p class="bold">主办单位</p>
        <p>国家建筑材料展贸中心</p>
        <p class="bold">指导单位</p>
        <p>中国建筑材料工业规划研究院</p>
        <p>中国建筑标准设计研究院</p>
        <p>清华大学清控人居集团</p>
        <p>中国土木工程学会总工程师委员会</p>
      </div>
    </div>
    <div class="awardInfo center info3">
      <div class="info">
        <p class="question">评审机构</p>
        <p><img class="chart" src="/img/m/chart.jpg" alt=""></p>
      </div>
    </div>
    <p class="pageTitle"><span class="title">视频中心</span></p>
    <video class="video" :src="getFileUrl('video/v1.mp4')" poster="/img/index/videoCover.jpg" controls="controls">
      您的浏览器不支持 video 标签。
    </video>
    <p class="pageTitle"><span class="title">评审专家</span></p>
    <div class="expertWrap">
      <Carousel loop dots="none" arrow="always">
        <CarouselItem v-for="(e, i) in expertList" :key='i'>
          <Row :gutter="15">
            <Col :lg="4" :sm="6" :xs="12" v-for="n in e" :key="n.id">
              <div class="expertItem">
                <img class="expertImg" :src="getImageUrl(n.avatar, 'square-small')" alt="">
                <p class="expertName oneRow">{{n.trueName}}</p>
                <p class="expertJob twoRows">{{n.company}}</p>
                <p class="expertJob twoRows">{{n.job}}</p>
              </div>
            </Col>
          </Row>
        </CarouselItem>
      </Carousel>
    </div>
    <p class="pageTitle"><span class="title">参评对象</span></p>
    <div class="objWrap">
      <Row :gutter="15">
        <Col span="8">
          <div class="objItem objItem1"><span class="objText">制造企业</span></div>
        </Col>
        <Col span="8">
          <div class="objItem objItem2"><span class="objText">设计企业</span></div>
        </Col>
        <Col span="8">
          <div class="objItem objItem3"><span class="objText">施工企业</span></div>
        </Col>
        <Col span="8">
          <div class="objItem objItem4"><span class="objText">工程项目</span></div>
        </Col>
        <Col span="8">
          <div class="objItem objItem5"><span class="objText">地产公司</span></div>
        </Col>
        <Col span="8">
          <div class="objItem objItem6"><span class="objText">科研院所</span></div>
        </Col>
        <Col span="8" push="4">
          <div class="objItem objItem7"><span class="objText">服务机构</span></div>
        </Col>
        <Col span="8" push="4">
          <div class="objItem objItem8"><span class="objText">个人参赛</span></div>
        </Col>
      </Row>  
    </div>
    <p class="pageTitle"><span class="title">参评类别</span></p>
    <div class="typeWrap">
      <Row>
        <Col span="12">
          <div class="typeItem typeItem1">
            <div><img class="typeIcon" src="/img/index/icon1.png" alt=""></div>
            <p>建筑应用创新综合类</p>
          </div>
        </Col>
        <Col span="12">
          <div class="typeItem typeItem2">
            <div><img class="typeIcon" src="/img/index/icon2.png" alt=""></div>
            <p>建筑应用创新单项类</p>
          </div>
        </Col>
      </Row>
    </div>
    <p class="pageTitle"><span class="title">参评要求</span></p>
    <div class="objWrap">
      <Row :gutter="15">
        <Col span="8">
          <div class="objItem objItem9"><span class="objText">符合规范</span></div>
        </Col>
        <Col span="8">
          <div class="objItem objItem10"><span class="objText">工程应用</span></div>
        </Col>
        <Col span="8">
          <div class="objItem objItem11"><span class="objText">社会责任</span></div>
        </Col>
        <Col span="8" push="4">
          <div class="objItem objItem12"><span class="objText">数量限制</span></div>
        </Col>
        <Col span="8" push="4">
          <div class="objItem objItem13"><span class="objText">体现创新</span></div>
        </Col>
      </Row>
    </div>
    <div class="timeWrap">
      <p class="timeTitle">时间安排</p>
      <Row>
        <Col span="16" push="8">
          <p>评审阶段</p>
          <p>2021年08月-2021年09月</p>
        </Col>
      </Row>
      <Row class="timeBorder">
        <Col span="8" :class="{'timeActive': timeStep==1}">
          <p>申报阶段</p>
          <p>2020年12月-2021年07月</p>
        </Col>
        <Col span="8" :class="{'timeActive': timeStep==2}"></Col>
        <Col span="8" :class="{'timeActive': timeStep==3}">
          <p>奖项发布</p>
          <p>2021年11月</p>
        </Col>
      </Row>
      <p class="right colorLight">*依照国家疫情相关政策确定时间</p>
    </div>
  </div>
</div>
</template>
<script>
import userService from "@/api/user";
import NewsService from "@/api/news";
import "../../assets/fullpage.min.css";
export default {
  data(){
    return{
      tabIndex: 1,
      newsList: [],
      newsList1: [],
      newsList2: [],
      newsList3: [],
      // projectNum: 12369,
      expertList: [],
      options: {//整屏插件vue-fullpage选项
        licenseKey: null,
        navigation: true, //是否显示导航，默认为false
        scrollBar: true, //true则是一滚动就是一整屏
      },
      isPc: false
    }
  },
  mounted(){
    let width = document.documentElement.clientWidth
    if(width<992){
      this.options.navigation = false
      this.isPc = false
    }else{
      this.isPc = true
    }

    this.getNewsList('大奖头条')
    this.getNewsList('官方动态')
    this.getNewsList('媒体报道')

    // this.getProjectCount()
    this.getJudgeList()
  },
  computed:{
    timeStep(){
      let now = new Date(), deadline = new Date('2021-07-31 23:59:59'), deadline2 =  new Date('2021-09-30 23:59:59');
      if(now>deadline2){
        return 3
      }else if(now>deadline){
        return 2
      }else{
        return 1
      }
    }
  },
  methods:{
    // 获取年月日
    getShortDate(date){
      if(date){
        return date.split('T')[0]
      }else{
        return ''
      }
    },
    // 获取列表
    getNewsList(type) {
      let condition = {
        page: 0,
        size: 2,
        sort: 'isTop,desc',
        'isHide.eq': 0,
        'type.eq': type
      };
      NewsService.getNews(condition)
        .then(res => {
          if (res.errorNo == 0) {
            if(type=='大奖头条'){
              this.newsList1 = res.data
            }else if(type=='官方动态'){
              this.newsList2 = res.data
            }else if(type=='媒体报道'){
              this.newsList3 = res.data
            }
            this.newsList = this[`newsList${this.tabIndex}`]
          } else {
            this.$Message.error(res.errorMessage);
          }
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
        });
    },
    // 获取评委列表
    getJudgeList() {
      let condition = {
        page: 0,
        size: 200,
        "userType.eq": "JUDGE"
      };
      userService.getJudges(condition)
        .then(res => {
          if (res.errorNo == 0) {
            if(this.isPc){
              this.expertList = this.groupList(res.data, 12)
            }else{
              this.expertList = this.groupList(res.data, 4)
            }
          } else {
            this.$Message.error(res.errorMessage);
          }
        })
        .catch((err) => {
          console.log(err.message)
        });
    },
    // 大数组分为小数组
    groupList(array, subGroupLength) {
      let index = 0;
      let newArray = [];
      while(index < array.length) {
          newArray.push(array.slice(index, index += subGroupLength));
      }
      return newArray;
    },
    // 修改新闻类型
    changeTab(val){
      this.tabIndex = val
      this.newsList = this[`newsList${val}`]
    },
    // getProjectCount(){
    //   userService.getProjectCount()
    //     .then(res => {
    //       if (res.errorNo == 0) {
    //         if (res.data) {
    //           this.projectNum = res.data;
    //         }
    //       } else {
    //         this.$Message.error(res.errorMessage);
    //       }
    //     })
    //     .catch(() => {
    //       this.$Message.error("连接服务器失败，请稍后再试");
    //     });
    // }
  }
}
</script>
<style lang="scss" scoped>
.mobile{
  overflow: auto;
  .mbannerWrap{
    position: relative;
    // padding-bottom: 61.21%;
    padding-bottom: 48.38%;
    .bannerImg{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }
  .awardInfo{
    position: relative;
    padding-bottom: 98.83%;
    line-height: 1.8em;
    background: no-repeat center;
    background-size: cover;
    overflow: auto;
    .info{
      padding: 0 15px 30px;
      position: absolute;
      top: 0;
      left: 0%;
      right: 0;
      .question{
        font-size: 24px;
        padding: 30px 0 20px;
      }
      .bold{
        font-weight: bold;
      }
      .chart{
        width: 100%;
      }
    }
  }
  .info1{
    background-image: url(/img/index/bg1.png);
  }
  .info2{
    background-image: url(/img/index/bg2.png);
  }
  .info3{
    background-image: url(/img/index/bg3.png);
  }
  .pageTitle{
    font-size: 24px;
    padding: 40px 20px;
    .title{
      padding-bottom: 5px;
      border-bottom: 3px solid #277a44;
    }
  }
  .expertWrap{
    padding: 0 15px;
  }
  .objWrap{
    padding: 0 15px 20px;
    .objItem{
      position: relative;
      padding-bottom: 100%;
      background: #F4F4F4 no-repeat top 30px center;
      background-size: 30px;
      text-align: center;
      margin-bottom: 15px;
      border-radius: 999px;
      .objText{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 25px;
      }
    }
    .objItem1{
      background-image: url(/img/index/icon_1_default.png);
    }
    .objItem2{
      background-image: url(/img/index/icon_2_default.png);
    }
    .objItem3{
      background-image: url(/img/index/icon_3_default.png);
    }
    .objItem4{
      background-image: url(/img/index/icon_4_default.png);
    }
    .objItem5{
      background-image: url(/img/index/icon_5_default.png);
    }
    .objItem6{
      background-image: url(/img/index/icon_6_default.png);
    }
    .objItem7{
      background-image: url(/img/index/icon_7_default.png);
    }
    .objItem8{
      background-image: url(/img/index/icon_8_default.png);
    }
    .objItem9{
      background-image: url(/img/m/i1.png);
    }
    .objItem10{
      background-image: url(/img/m/i2.png);
    }
    .objItem11{
      background-image: url(/img/m/i3.png);
    }
    .objItem12{
      background-image: url(/img/m/i4.png);
    }
    .objItem13{
      background-image: url(/img/m/i5.png);
    }
  }
  .typeWrap{
    padding: 0 15px;
    .typeItem{
      text-align: center;
      color: #fff;
      padding: 30px 0;
      background: no-repeat center;
      background-size: cover;
      .typeIcon{
        height: 100px;
        margin-bottom: 10px;
      }
    }
    .typeItem1{
      background-image: url(/img/index/bg4.png);
    }
    .typeItem2{
      background-image: url(/img/index/bg5.png);
    }
  }
}
.pc{
  .homeBannerWrap{
    position: relative;
    overflow: hidden;
    z-index: 1;
    .banner{
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  .pageItem{
    width: 80%;
    margin: auto;
    color: #8C8C8C;
    // padding: 30px 0 50px;
    line-height: 1.6em;
  }
  .news{
    position: relative;
    padding-right: 210px;
    padding-bottom: 20px;
    .title{
      color: #4C5C4E;
      font-size: 16px;
      padding-bottom: 20px;
    }
    .tabWrap{
      border-bottom: 1px solid #DCDDE0;
      margin-bottom: 10px;
      .tabItem{
        position: relative;
        display: inline-block;
        margin-right: 15px;
        cursor: pointer;
        padding-bottom: 10px;
      }
      .tabItem.tabActive{
        cursor: default;
        pointer-events: none;
        color: #4C5C4E;
      }
      .tabItem.tabActive::after{
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -1px;
        height: 2px;
        background: #277A44;
      }
    }
    .newsName{
      color: #8C8C8C;
    }
    .right{
      text-align: right;
    }
    .colorDark{
      color: #4C5C4E;
    }

    .projectNum{
      position: absolute;
      right: 0;
      top: 0;
      width: 180px;

      .bigNum{
        font-size: 28px;
        padding: 15px 0;

        .unit{
          font-size: 14px;
          color: #277A44;
        }
      }
    }
  }
  .introWrap{
    .item{
      position: relative;
      padding-bottom: 100%;
      background: no-repeat center;
      background-size: cover;
      overflow: hidden;
      .info, .moreInfo{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 100%;
        padding: 30px;
        font-size: 16px;
        color: #4D4D4D;
        overflow: auto;
      }
      .moreInfo{
        top: 100%;
        background: #277A44;
        transition: 0.5s ease-in-out;
        color: #fff;
        text-align: center;
        opacity: 0;
        .question{
          text-align: left;
          padding-bottom: 25px;
        }
        .answer{
          font-size: 14px;
          line-height: 1.8em;
          .bold{
            font-weight: bold;
            font-size: 14px;
          }
          .chart{
            width: 100%;
          }
        }
      }
      /* 设置滚动条的样式 */
      .myBar::-webkit-scrollbar {
        width: 10px;
      }
    }
    .item:hover .moreInfo{
      opacity: 1;
      top: 0;
    }
    .item1{
      background-image: url(/img/index/bg1.png);
    }
    .item2{
      background-color: #F2F2F2;
      background-image: url(/img/index/bg2.png);
    }
    .item3{
      background-image: url(/img/index/bg3.png);
    }
  }
  .pageTitle{
    padding: 0 0 50px;
    text-align: center;
    color: #4C5C4E;
    font-size: 24px;
    .title{
      padding-bottom: 5px;
      border-bottom: 3px solid #277a44;
    }
  }
  .objItem{
    background: #F4F4F4 no-repeat top 30px center;
    background-size: 30px;
    padding: 80px 0 30px;
    text-align: center;
    margin-bottom: 15px;

    &:hover{
      background-color: #277A44;
      color: #fff;
    }
  }
  .objItem1{
    background-image: url(/img/index/icon_1_default.png);
    &:hover{
      background-image: url(/img/index/icon_1_hover.png);
    }
  }
  .objItem2{
    background-image: url(/img/index/icon_2_default.png);
    &:hover{
      background-image: url(/img/index/icon_2_hover.png);
    }
  }
  .objItem3{
    background-image: url(/img/index/icon_3_default.png);
    &:hover{
      background-image: url(/img/index/icon_3_hover.png);
    }
  }
  .objItem4{
    background-image: url(/img/index/icon_4_default.png);
    &:hover{
      background-image: url(/img/index/icon_4_hover.png);
    }
  }
  .objItem5{
    background-image: url(/img/index/icon_5_default.png);
    &:hover{
      background-image: url(/img/index/icon_5_hover.png);
    }
  }
  .objItem6{
    background-image: url(/img/index/icon_6_default.png);
    &:hover{
      background-image: url(/img/index/icon_6_hover.png);
    }
  }
  .objItem7{
    background-image: url(/img/index/icon_7_default.png);
    &:hover{
      background-image: url(/img/index/icon_7_hover.png);
    }
  }
  .objItem8{
    background-image: url(/img/index/icon_8_default.png);
    &:hover{
      background-image: url(/img/index/icon_8_hover.png);
    }
  }
  .typeWrap{
    .typeItem{
      text-align: center;
      background: #277A44;
      color: #fff;
      position: relative;
      margin-bottom: 15px;
      padding: 30px 0;
      .typeText{
        padding: 0 30px;
        line-height: 1.6em;
        height: 8em;
      }
      .typeIconWrap{
        position: absolute;
        top: 0;
        bottom: 0;
        background: no-repeat center;
        background-size: cover;
        width: 140px;
        .typeIcon{
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
          margin-top: -51px;
          padding-top: 80px;
          background: no-repeat top center;
          background-size: 60px;
        }
      }
    }
    .typeItem1{
      padding-right: 140px;
      .typeIconWrap{
        right: 0;
        background-image: url(/img/index/bg4.png);
        .typeIcon{
          background-image: url(/img/index/icon1.png);
        }
      }
    }
    .typeItem2{
      padding-left: 140px;
      .typeIconWrap{
        left: 0;
        background-image: url(/img/index/bg5.png);
        .typeIcon{
          background-image: url(/img/index/icon2.png);
        }
      }
    }
  }
  .ruleWrap{
    padding-bottom: 50px;
    text-align: center;
    .myCol{
      width: 20%;
      .ruleItem{
        margin: 0 8px;
        position: relative;
        padding-bottom: 100%;
        background: no-repeat center;
        background-size: cover;
        color: #277A44;
        overflow: hidden;
        .ruleIcon{
          position: absolute;
          left: 0;
          right: 0;
          top: 50%;
          padding-top: 35px;
          background: no-repeat top center;
          background-size: 30px;
          margin-top: -28px;
        }
        .moreInfo{
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          height: 100%;
          background: #277A44;
          transition: 0.5s ease-in-out;
          color: #fff;
          opacity: 0;
          font-size: 12px;
          padding: 15px;
        }
      }
      .ruleItem:hover .moreInfo{
        opacity: 1;
        top: 0;
      }
    }
    .myCol:nth-child(1) .ruleItem{
      background-image: url(/img/index/bg6.jpg);
      margin-bottom: 60px;
      .ruleIcon{
        background-image: url(/img/index/icon3.png);
      }
    }
    .myCol:nth-child(2) .ruleItem{
      background-image: url(/img/index/bg7.jpg);
      margin-top: 60px;
      .ruleIcon{
        background-image: url(/img/index/icon4.png);
      }
    }
    .myCol:nth-child(3) .ruleItem{
      margin-bottom: 60px;
      background-image: url(/img/index/bg8.jpg);
      .ruleIcon{
        background-image: url(/img/index/icon5.png);
      }
    }
    .myCol:nth-child(4) .ruleItem{
      background-image: url(/img/index/bg9.jpg);
      margin-top: 60px;
      .ruleIcon{
        background-image: url(/img/index/icon6.png);
      }
    }
    .myCol:nth-child(5) .ruleItem{
      margin-bottom: 60px;
      background-image: url(/img/index/bg10.jpg);
      .ruleIcon{
        background-image: url(/img/index/icon7.png);
        padding-top: 45px;
        margin-top: -34px;
      }
    }
    @media screen and (max-width:992px) {
      .myCol{
        width: 33.33%;
      }
    }
    @media screen and (max-width:768px) {
      .myCol{
        width: 50%;
      }
    }
  }
  .contactWrap{
    padding: 20px;
    line-height: 2em;
    .contactTitle{
      padding-bottom: 5px;
      color: #4C5C4E;
    }
    .contactIcon{
      font-size: 20px;
      padding-right: 5px;
      vertical-align: middle;
    }
  }
  .adImg{
    width: 100%;
  }
}
.video{
  width: 100%;
}
.expertWrap{
  padding: 0 80px;
  .expertItem{
    padding: 20px 8px;
    text-align: center;
    margin-bottom: 15px;
    .expertImg{
      width: 70%;
    }
    .expertName{
      font-size: 16px;
      color: #1B1B1B;
      padding: 5px 0 3px;
    }
    .expertJob{
      font-size: 12px;
    }
    &:hover{
      background: #F4F4F4;
    }
  }
}
.timeWrap{
  background: #277A44;
  padding: 20px;
  color: #fff;
  .timeTitle{
    font-size: 24px;
    padding-bottom: 15px;
  }
  .timeBorder{
    border-top: 4px solid #fff;
    padding-top: 10px;
    margin-top: 10px;
  }
  .right{
    text-align: right;
    padding-top: 10px;
  }
  .colorLight{
    color: #93C9A6;
  }
  .timeActive{
    color: #E8D687;
    position: relative;
    &::before{
      content: '';
      position: absolute;
      height: 4px;
      top: -14px;
      left: 0;
      right: 0;
      background: #E8D687;
    }
  }
}
</style>