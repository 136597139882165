<template>
  <div class="expert">
    <div class="bannerWrap">
      <img class="banner" src="/img/banner.jpg" alt="">
    </div>
    <div class="section">
      <Row>
        <Col :lg="18" :xs="24" class="leftInfo">
          <div class="info">
            <p class="mobile title center">评审专家</p>
            <Row :gutter="15">
              <Col :lg="4" :sm="6" :xs="12" v-for="n in list" :key="n.id">
                <div class="expertItem">
                  <img class="expertImg" :src="getImageUrl(n.avatar, 'square-small')" alt="">
                  <p class="expertName oneRow">{{n.trueName}}</p>
                  <p class="expertJob twoRows" :title="n.company">{{n.company}}</p>
                  <p class="expertJob twoRows" :title="n.job">{{n.job}}</p>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col span="6" class="pc">
          <img class="time" src="/img/introduction/time.png" alt="">
          <div id="contact" class="contact">
            <p class="title">联系我们</p> 
            <div class="clearfix">
              <div class="label">评审组委会：</div>
              <div class="info">010-88083947/2319</div>  
            </div>
            <div class="clearfix">
              <div class="label">组委会邮箱：</div>
              <div class="info">cbmea2006@126.com</div>  
            </div>
            <div class="clearfix">
              <div class="label">组委会地址：</div>
              <div class="info">北京市海淀区三里河路甲11号 中国建材大厦C座3层</div>  
            </div>
            <div class="clearfix">
              <div class="label">联&nbsp;&nbsp;&nbsp;系&nbsp;&nbsp;&nbsp;人：</div>
              <div class="info">13901127933(刘秀明)</div>  
            </div>
            <div class="clearfix">
              <div class="label">微信公众号：</div>
              <div class="info">国家建筑应用创新大奖</div>  
            </div>
            <p class="center"><img class="wxCode" src="/img/wxCode.jpg" alt=""></p>
          </div>
        </Col>
      </Row>
    </div>
  </div>
</template>

<script>
import userService from "@/api/user";
export default {
  data(){
    return{
      list: []
    }
  },
  mounted(){
    this.getJudgeList()
  },
  methods:{
    // 获取评委列表
    getJudgeList() {
      let condition = {
        page: 0,
        size: 200,
        "userType.eq": "JUDGE"
      };
      userService.getJudges(condition)
        .then(res => {
          if (res.errorNo == 0) {
              this.list = res.data
          } else {
            this.$Message.error(res.errorMessage);
          }
        })
        .catch((err) => {
          console.log(err.message)
        });
    },
  }
}
</script>

<style lang="scss" scoped>
.expert{
  .section{
    width: 80%;
    margin: auto;
    color: #8C8C8C;
    line-height: 1.8em;
    .leftInfo{
      border-right: 1px solid #E9E9E9;
      .info{
        padding: 50px 15px;
        .expertItem{
          padding: 20px 8px;
          text-align: center;
          margin-bottom: 15px;
          .expertImg{
            width: 70%;
            border-radius: 800px;
          }
          .expertName{
            font-size: 16px;
            color: #377652;
            padding: 5px 0 3px;
          }
          .expertJob{
            font-size: 12px;
          }
          &:hover{
            background: #F4F4F4;
          }
        }
      }
    }
    .time{
      width: 100%;
      padding-top: 50px;
    }
  }
  @media screen and (max-width: 992px) {
    .section{
      width: 100%;
      .leftInfo{
        border-right: none;
        .info{
          padding-bottom: 0;
          .title{
            font-size: 24px;
            padding-bottom: 20px;
            font-weight: bold;
            color: #4D4D4D;
          }
        }
      }
    }
  }
}
</style>