<template>
  <div class="notes">
    <div class="bannerWrap">
      <img class="banner" src="/img/banner.jpg" alt="">
    </div>
    <div class="section">
      <Row>
        <Col :lg="18" :xs="24" class="leftInfo">
          <div class="info">
            <p class="mobile title center">参评须知</p>
            <p class="subTitle">参评对象</p>
            <p>参评申报单位或个人主要为（包括但不限于）在建筑工程中有应用创新的制造企业、设计企业、施工企业、工程项目、地产公司、科研院所、服务机构等。</p>
            <p class="subTitle">参评类别</p>
            <p id="1" class="typeTitle">建筑应用创新综合类</p>
            <p>在建筑材料、部品部件、设备设施、设计、施工工艺、新技术与新施工设备、智能化、装配式建筑、建筑节能、既有建筑改造等多个方面的具有创新的工程应用项目。</p>
            <p><img class="typeImg" src="/img/notes/bg1.jpg" alt=""></p>
            <p id="2" class="typeTitle typeTitle2">建筑应用创新单项类</p>
            <Row class="typeWrap">
              <Col :lg="12" :xs="24"><img class="typeImg" src="/img/notes/bg2.png" alt=""></Col>
              <Col :lg="12" :xs="{span:24,'class-name':'center'}">
                <div class="textInfo">
                  <p class="title">建筑材料应用创新类</p>
                  <p>应用在工程项目中具有创新性的材料。如：生态建材、功能材料、装饰材料、结构材料等。</p>
                </div>
              </Col>
            </Row>
            <Row class="typeWrap">
              <Col :lg="{span:12,push:12}" :xs="24"><img class="typeImg" src="/img/notes/bg3.png" alt=""></Col>
              <Col :lg="{span:12,pull:12,'class-name':'right'}" :xs="{span:24,'class-name':'center'}">
                <div class="textInfo">
                  <p class="title">装配式建筑应用创新类</p>
                  <p>以构件部品化、装配化、服务定制化、运输智能化、安装高效化等创新性为基础的建筑工程。装配式建筑主体结构系统、外围护系统、管道、装饰装配一体化系统等相关建筑材料产品。</p>
                </div>
              </Col>
            </Row>
            <Row class="typeWrap">
              <Col :lg="12" :xs="24"><img class="typeImg" src="/img/notes/bg4.png" alt=""></Col>
              <Col :lg="12" :xs="{span:24,'class-name':'center'}">
                <div class="textInfo">
                  <p class="title">建筑部品部件应用创新类</p>
                  <p>应用在工程项目中对已有成熟部品部件的质量、性能、加工工艺、施工简易程度有较大提升的改进型部品部件。如：建筑构件、家居、养老、酒店等智能化建筑全体系控制、软硬件服务及整体解决方案产品及其它可提升人居环境水平的具有创新性的部品部件产品。</p>
                </div>
              </Col>
            </Row>
            <Row class="typeWrap">
              <Col :lg="{span:12,push:12}" :xs="24"><img class="typeImg" src="/img/notes/bg5.png" alt=""></Col>
              <Col :lg="{span:12,pull:12,'class-name':'right'}" :xs="{span:24,'class-name':'center'}">
                <div class="textInfo">
                  <p class="title">设备设施应用创新类</p>
                  <p>应用在工程项目中具有创新性的设备和设施。建筑设备如：电气、给水、排水、采暖、通风、空调、电梯、通讯及楼宇智能化等设备设施。</p>
                </div>
              </Col>
            </Row>
            <Row class="typeWrap">
              <Col :lg="12" :xs="24"><img class="typeImg" src="/img/notes/bg6.png" alt=""></Col>
              <Col :lg="12" :xs="{span:24,'class-name':'center'}">
                <div class="textInfo">
                  <p class="title">其它应用创新类</p>
                  <p>除上述的创新外，在建筑应用中表现出自己的独特价值，包括：设计、施工工艺、产品技术、既有建筑改造、材料再利用等。</p>
                  <div class="mobile">
                    <Row class="center" :gutter="15">
                      <Col span="12" class="eqHeight">
                        <div class="moreBg">
                          <p>设计</p>
                          <p>在建筑设计方面具有创新的规划方案、建筑设计、深化设计等阶段</p>  
                        </div>
                      </Col>
                      <Col span="12" class="eqHeight">
                        <div class="moreBg">
                          <p>施工工艺</p>
                          <p>与以往的施工技术不同，具有新技术、新工艺等施工类的创新</p>
                        </div>
                      </Col>
                    </Row>
                    <Row class="center" :gutter="15">
                      <Col span="12" class="eqHeight">
                        <div class="moreBg">
                          <p>新技术</p>
                          <p>具有创新的技术及技术应用。如：BIM/TEM技术、3D打印技术、SI分离技术、地坪/砌筑/抹灰/安装机器人等</p>  
                        </div>
                      </Col>
                      <Col span="12" class="eqHeight">
                        <div class="moreBg">
                          <p>智能化</p>
                          <p>对建筑引入智能化场景、管理、大数据分析、智能化控制、智能化解决方案等</p>
                        </div>
                      </Col>
                    </Row>
                    <Row class="center" :gutter="15">
                      <Col span="12" class="eqHeight">
                        <div class="moreBg">
                          <p>建筑节能</p>
                          <p>在建筑节能方面具有创新的理念、体系、系统等</p>  
                        </div>
                      </Col>
                      <Col span="12" class="eqHeight">
                        <div class="moreBg">
                          <p>既有建筑改造</p>
                          <p>针对既有建筑的实际问题进行的创新解决方案。包含：既有建筑的维护、维修、改造及既有建筑的升级与更新</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
                <span class="pc more"></span>
                <div class="pc moreInfo myBar">
                  <p>设计：在建筑设计方面具有创新的规划方案、建筑设计、深化设计等阶段；</p>
                  <p>施工工艺：与以往的施工技术不同，具有新技术、新工艺等施工类的创新；</p>
                  <p>新技术：具有创新的技术及技术应用。如：BIM/TEM技术、3D打印技术、SI分离技术、地坪/砌筑/抹灰/安装机器人等；</p>
                  <p>智能化：对建筑引入智能化场景、管理、大数据分析、智能化控制、智能化解决方案等；</p>
                  <p>建筑节能：在建筑节能方面具有创新的理念、体系、系统等；</p>
                  <p>既有建筑改造：针对既有建筑的实际问题进行的创新解决方案。包含：既有建筑的维护、维修、改造及既有建筑的升级与更新。</p>
                </div>
              </Col>
            </Row>
            <p class="subTitle">参评要求</p>
            <p class="hang"><span class="num">1</span>符合国家相关的规范、标准及规定，满足国家工程建设标准强制性条文。贯彻“适用、经济、绿色、美观”的建筑方针，突出建筑使用功能以及节能、节水、节地、节材和环保。</p>
            <p class="hang"><span class="num">2</span>申报项目须应用于工程中，<span class="bold">注重新技术和新材料创新应用</span>。</p>
            <p class="hang"><span class="num">3</span>履行社会责任，具有显著的社会、经济和环境效益。</p>
            <p class="hang"><span class="num">4</span>每个单位或个人申报<span class="bold">同类产品不超过二个</span>。</p>
            <p class="hang"><span class="num">5</span>申报项目必须<span class="bold">完工满一年</span>。</p>
            <p class="hang"><span class="num">6</span>申报项目应充分体现在建筑应用创新方面的特点，在技术创新（材料、工艺、设计、管理）、绿色健康、行业引领、社会责任等方面能带领行业发展，具有良好的业内评价和市场认知度。</p>
            <p class="subTitle bigLeft">申报资料及申报程序</p>
            <p class="hang"><span class="num">1</span>登录建筑应用创新大奖官网报名入口，根据提示提交完整的电子版参评资料表（每个单位或个人申报同类产品不超过二个）。</p>
            <p class="hang"><span class="num">2</span>建筑应用创新大奖官网报名入口，提交参评项目的相关图片（视频）。</p>
            <p class="indent colorTitle"><span class="num circle">1</span>项目图片主要包括：</p>
            <p class="subIndent">·设计图</p>
            <p class="subIndent">·实景/实物图</p>
            <p class="subIndent">·重点突出创新内容的图片。照片尽可能从多个角度拍摄，同时尽可能多的添加项目细部图、施工照片、设计图等能重点突出创新的图片。</p>
            <p class="indent colorTitle"><span class="num circle">2</span>参评图片要求:</p>
            <p class="subIndent">·分辨率：300dpi。</p>
            <p class="subIndent">·颜色模式：CMYK颜色</p>
            <p class="subIndent">·格式：“.JPG”</p>
            <p class="subIndent">·图片大小：不低于2M</p>
            <p class="indent colorTitle"><span class="num circle">3</span>其他补充材料图片。</p>
            <p class="subIndent">产品执行证明、专利技术、科研成果、项目所获奖励、推荐单位意见、行业内类似奖项等其他补充性说明，请提供相应证明格式的扫描件。</p>
            <p class="subTitle">免责须知</p>
            <p class="hang"><span class="num">1</span>加入此次参评单位或个人须按大奖组委会对赛事的统一要求，进行网络推广和团队组建和一些社会活动。</p>
            <p class="hang"><span class="num">2</span>参评单位或个人确认并接受甲方及其组织活动的形象和价值，并同意在参评时，均已促进和提升甲方和其组织活动的形象、利益为宗旨。</p>
            <p class="hang"><span class="num">3</span>参评单位或个人同意不得采取任何方式或为任何目的而向任何第三方或向公众声明其已得到甲方的“选定”、“首选”或“认可”。</p>
            <p class="hang"><span class="num">4</span>不从事任何欺诈、误导或不道德行为，包括但不限于任何参评合作伙伴或参评单位及个人的任何竞争对手的任何诋毁。</p>
            <p class="hang"><span class="num">5</span>申报作品均为原创，未侵犯任何他人的任何专利、著作权、商标权及其他知识产权。申报作品发生任何侵权行为，一经核实，主办方将取消其参评及获奖资格。</p>
            <p class="hang"><span class="num">6</span>主办单位享有对申报作品及相关材料进行宣传使用、公开展览和出版发行等权利。</p>
            <p class="hang"><span class="num">7</span>申报作品如有质量问题将采取评审一票否决制，不予入选。</p>
            <p class="hang"><span class="num">8</span>申报者提交作品之前需仔细阅读上述条款，充分理解并表示同意，如果本次申报作品或者申报对第三方造成侵权、损害或者其他纠纷，由申报者本人承担一切法律责任，与主办单位无关。</p>
            <p class="hang colorGreen"><span class="num">9</span>主办方对申报及评选具有最终解释权、裁定权。</p>
          </div>
        </Col>
        <Col span="6" class="pc">
          <div class="contact">
            <a :href="getFileUrl('file/%E5%8F%82%E8%AF%84%E9%A1%B9%E7%9B%AE-%E7%94%B3%E6%8A%A5%E8%A1%A8.docx')" download="参评项目-申报表.docx" class="downloadBtn">
              <Icon type="md-download" class="btnIcon" />
              <span>参评申报表电子版下载</span>
            </a>
            <a :href="getFileUrl('file/%E5%8F%82%E8%AF%84%E9%A1%B9%E7%9B%AE-%E8%B5%84%E6%96%99%E8%A1%A8.docx')" download="参评项目-资料表.docx" class="downloadBtn">
              <Icon type="md-download" class="btnIcon" />
              <span>参评资料表电子版下载</span>
            </a>
            <!-- <a href="/" class="downloadBtn bgBtn2">
              <Icon type="md-download" class="btnIcon" />
              <span>申报材料规格示例下载</span>
            </a> -->
            <router-link class="signBtn" to="/declare/basic">
              <Icon type="md-create" class="btnIcon" />
              <span>报名入口</span>
            </router-link>
            <div id="contact">
              <p class="title">联系我们</p> 
              <div class="clearfix">
                <div class="label">评审组委会：</div>
                <div class="info">010-88083947/2319</div>  
              </div>
              <div class="clearfix">
                <div class="label">组委会邮箱：</div>
                <div class="info">cbmea2006@126.com</div>  
              </div>
              <div class="clearfix">
                <div class="label">组委会地址：</div>
                <div class="info">北京市海淀区三里河路甲11号 中国建材大厦C座3层</div>  
              </div>
              <div class="clearfix">
                <div class="label">联&nbsp;&nbsp;&nbsp;系&nbsp;&nbsp;&nbsp;人：</div>
                <div class="info">13901127933(刘秀明)</div>  
              </div>
              <div class="clearfix">
                <div class="label">微信公众号：</div>
                <div class="info">国家建筑应用创新大奖</div>  
              </div>
              <p class="center"><img class="wxCode" src="/img/wxCode.jpg" alt=""></p>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  </div>
</template>
<script>
export default {
  mounted(){
    let id = this.$route.query.id
    if(id){
      setTimeout(()=>{
        document.getElementById(id).scrollIntoView({behavior: "smooth"})
      }, 1000)
    }
  }
}
</script>
<style lang="scss" scoped>
.notes{
  .section{
    width: 80%;
    margin: auto;
    color: #8C8C8C;
    line-height: 1.8em;
    .leftInfo{
      border-right: 1px solid #E9E9E9;
      .info{
        padding: 10px 15px 50px;
        .ivu-col-lg-class-name-left{
          text-align: left;
        }
        .ivu-col-lg-class-name-right{
          text-align: right;
        }
        .hang{
          text-indent:-23px;
          padding-left: 23px;
        }
        .indent{
          text-indent: 20px;
        }
        .subIndent{
          text-indent: 40px;
        }
        .colorTitle{
          color: #545454;
        }
        .num{
          display: inline-block;
          background: #277A44;
          color: #FFFFFF;
          width: 18px;
          line-height: 18px;
          text-align: center;
          margin: 0 5px 2px 0;
          text-indent: 0;
          padding-left: 0;
        }
        .circle{
          border-radius: 18px;
          background: #47B36D;
        }
        .bold{
          font-weight: bold;
        }
        .subTitle{
          padding: 10px 15px;
          margin: 30px 0 15px;
          background: linear-gradient(to right, #46B36C, #FFFFFF);
          color: #FFFFFF;
        }
        .typeTitle{
          padding: 5px 0;
          margin-bottom: 5px;
          text-align: center;
          background: linear-gradient(to right, #F5E09D, #FFFFFF, #F5E09D);
        }
        .typeTitle2{
          background: linear-gradient(to right, #E1E4E0, #FFFFFF, #E1E4E0);
        }
        .typeWrap{
          margin: 10px 0 15px;
          background: #F9F9F9;
          line-height: 0;
          overflow: hidden;
        }
        .typeImg{
          width: 100%;
        }
        .textInfo{
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          padding: 15px 20px;
          color: #1B1B1B;
          line-height: 1.8em;
          .title{
            font-size: 16px;
            padding-bottom: 15px;
            font-weight: bold;
          }
        }
        .more{
          position: absolute;
          bottom: 0;
          right: 0;
          width: 30px;
          height: 30px;
          background: url(/img/notes/bg7.png) no-repeat;
          background-size: 30px;
        }
        .moreInfo{
          position: absolute;
          left: 0;
          right: 0;
          top: 100%;
          bottom: 0;
          background: #277A44;
          color: #FFFFFF;
          padding: 15px 20px;
          line-height: 1.6em;
          font-size: 12px;
          overflow: auto;
          transition: .8s ease-in-out;
        }
        .more:hover + .moreInfo, .moreInfo:hover{
          top: 0;
        }
      }
    }
    .contact{
      padding: 50px 0 0 20px;
      line-height: 1.8em;
      .downloadBtn{
        display: block;
        padding: 20px 0;
        text-align: center;
        margin-bottom: 20px;
        background: #E1E4E0;
        color: #737373;
        &:hover{
          background: #277A44;
          color: #FFFFFF;
        }
        .btnIcon{
          font-size: 16px;
          margin-right: 5px;
        }
      }
      .signBtn{
        display: block;
        padding: 20px 0;
        text-align: center;
        margin-bottom: 20px;
        background: linear-gradient(to right, #40CD90, #277A44);
        font-size: 16px;
        color: #FFFFFF;
        .btnIcon{
          font-size: 18px;
        }
      }
    }
  }
  @media screen and (max-width: 992px) {
    .section{
      width: 100%;
      .leftInfo{
        border-right: none;
        .info{
          padding-bottom: 0;
          .ivu-col-xs-class-name-center{
            text-align: center;
          }
          .title{
            font-size: 24px;
            padding-top: 20px;
            font-weight: bold;
            color: #4D4D4D;
          }
          .mName{
            padding-bottom: 20px;
          }
          .subTitle{
            background: transparent;
            color: #4D4D4D;
            padding: 0;
            font-size: 18px;
            position: relative;
            &::after{
              content: '';
              position: absolute;
              right: 0;
              top: 6px;
              bottom: 6px;
              left: 80px;
              background: linear-gradient(to right, #46B36C, #FFFFFF);
            }
          }
          .bigLeft::after{
            left: 165px;
          }
          .textInfo{
            position: static;
            transform: translateY(0);
            padding-bottom: 30px;
            .eqHeight{
              -webkit-box-flex: 1;
              -webkit-flex: 1;
              flex: 1;
              width: 0;
              margin-top: 20px;
            }
            .moreBg{
              background: #277A44;
              color: #FFFFFF;
              padding: 15px 10px;
              height: 100%;
            }
          }
        }
      }
    }
  }
}
</style>