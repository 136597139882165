<template>
  <div class="userFrom">
    <Row>
      <Col span="12" align="center"><span @click="changeWay(1)" class="wayText" :class="{wayActive: way==1}">账号密码登录</span></Col>
      <Col span="12" align="center"><span @click="changeWay(2)" class="wayText" :class="{wayActive: way==2}">短信验证码登录</span></Col>
    </Row>
    <Form key="login" ref="login" :model="form" hide-required-mark :label-width="60" label-position="left">
      <div class="inputWrap">
        <FormItem label="手机号" prop="mobilePhone" :rules="mobilePhoneRules">
          <Input v-model="form.mobilePhone" :border="false"></Input>    
        </FormItem>  
      </div>
      <div class="inputWrap" v-if="way==1">
        <FormItem label="密 码" prop="password" :rules="keyRules">
          <Input type="password" v-model="form.password" :border="false" password @on-enter="login"></Input>    
        </FormItem>
      </div>
      <div class="msgWrap" v-if="way==2">
        <div class="inputWrap">
          <FormItem label="验证码" prop="captcha" :rules="keyRules">
            <Input v-model="form.captcha" :border="false" @on-enter="login"></Input>    
          </FormItem>
        </div>
        <Button class="defaultBtn msgBtn" shape="circle" :disabled="codeBtnText!='获取验证码'" @click="sendCaptcha">{{codeBtnText}}</Button>  
      </div>
      <Button class="defaultBtn" long shape="circle" :loading="submitting" @click="login">登&nbsp;&nbsp;&nbsp;&nbsp;录</Button>
    </Form>
    <Row>
      <Col span="14"><span>还没有账号，</span><router-link class="colorGreen" :to="`/user/register?fromUrl=${$route.query.fromUrl}`">去注册</router-link></Col>
      <Col span="10" align="right"><router-link class="colorGreen" :to="`/user/reset_password?fromUrl=${$route.query.fromUrl}`">找回密码</router-link></Col>
    </Row>
  </div>
</template>

<script>
import userService from "@/api/user";
export default {
  data() {
    return {
      way: 1,
      form: {},
      mobilePhoneRules: [
        {
          required: true,
          pattern: /^1[3456789]\d{9}$/,
          message: "请输入手机号码",
          trigger: "blur"
        }
      ],
      keyRules: [
        { required: true, message: "内容不能为空", trigger: "blur,change" }
      ],
      submitting: false,
      codeBtnText: "获取验证码"
    };
  },
  methods: {
    // 切换登录方式
    changeWay(t) {
      t != this.way && (this.way = t);
    },
    // 获取验证码
    sendCaptcha() {
      this.$refs.login.validateField("mobilePhone", valid => {
        if (valid) {
          this.$Message.error("请输入正确的手机号");
        } else {
          let condition = {
            smsType: "LOGIN",
            mobilePhone: this.form.mobilePhone
          };
          let timer = null;
          userService.sendCaptcha(condition).then(res => {
            if (res.errorNo == 0) {
              this.$Message.success("验证码发送成功");
              let time = 60,
                _this = this;
              timer = setInterval(function() {
                time--;
                _this.codeBtnText = `获取验证码${time}`;
                if (time == 0) {
                  clearInterval(timer);
                  _this.codeBtnText = "获取验证码";
                }
              }, 1000);
            } else {
              this.$Message.error(res.errorMessage);
            }
          });
          this.$once("hook:beforeDestroy", () => {
            clearInterval(timer);
            this.codeBtnText = "获取验证码";
          });
        }
      });
    },
    // 登录
    login() {
      this.$refs.login.validate(valid => {
        if (valid) {
          this.submitting = true;
          let condition = { loginName: this.form.mobilePhone };
          if (this.way == 1) {
            condition.key = this.form.password;
          }
          if (this.way == 2) {
            condition.key = this.form.captcha;
          }
          condition.accountType = "DESIGNER";
          userService
            .login(condition)
            .then(res => {
              if (res.errorNo == 0) {
                this.$store.commit("setToken", res.data.accessToken);
                let user = res.data.user;
                this.$store.commit("setUserInfo", user);
                window.localStorage.setItem("login_time", new Date().getTime());
                this.$router.push("/declare/basic");
                // this.getCompetitionRegistration(user.id);
              } else if (res.errorNo == 9013) {
                if (
                  res.errorMessage.split(",").length > 1 &&
                  res.errorMessage.split(",")[0] == 5054
                ) {
                  this.$Message.error("用户不存在");
                } else {
                  this.$Message.error("密码错误");
                }
              } else {
                this.$Message.error(res.errorMessage);
              }
              this.submitting = false;
            })
            .catch(() => {
              this.submitting = false;
              this.$Message.error("连接服务器失败，请稍后再试");
            });
        } else {
          this.$Message.error("请输入正确的手机号和密码(验证码)");
        }
      });
    },
    // 获取报名信息
    // getCompetitionRegistration(userId) {
    //   userService
    //     .getCompetitionRegistration({
    //       "userId.eq": userId
    //     })
    //     .then(res => {
    //       if (res.errorNo == 0) {
    //         if (res.data.length > 0) {
    //           this.$router.push("/declare/project");
    //         } else {
    //           this.$router.push("/declare/basic");
    //         }
    //       } else {
    //         this.$router.push("/declare/basic");
    //       }
    //     })
    //     .catch(() => {
    //       this.$router.push("/declare/basic");
    //     });
    // }
  }
};
</script>
<style lang="scss">
.userFrom {
  .wayText {
    display: inline-block;
    color: #3f4641;
    cursor: pointer;
    margin-bottom: 30px;
    font-size: 16px;
  }
  .wayActive {
    cursor: default;
    font-weight: bold;
    border-bottom: 2px solid #277a44;
  }
  .inputWrap {
    background: #eaeaea;
    padding: 3px 15px;
    border-radius: 100px;
    margin-bottom: 24px;

    .ivu-form-item {
      margin-bottom: 0;
      .ivu-input {
        background: transparent;
      }
    }
    .ivu-form-item-error .ivu-input {
      border: transparent;
    }
  }
  .msgWrap {
    position: relative;
    padding-right: 130px;

    .msgBtn {
      position: absolute;
      right: 0;
      top: 0;
      margin: 0;
      padding: 0 20px;
    }
  }
  .defaultBtn {
    margin: 10px 0;
    height: 40px;
  }
  .defaultBtn[disabled] {
    background: rgba(#277a44, 0.8);
  }
}
</style>