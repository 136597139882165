  /*
   * Copyright(c) 2021 architectural award, All Rights Reserved.
   * Author: lxj
   */
  import fetch from '@/utils/fetch';

  export default {
    // 获取评委列表
    getJudges: params => {
      return fetch({
        url: 'inquiry/competition/judge',
        method: 'get',
        params
      })
    },
    // 获取时间
    getEndDate: id => {
      return fetch({
        url: `inquiry/competitions/${id}`,
        method: 'get'
      })
    },
    // 登录
    login: data => {
      return fetch({
        url: 'login',
        method: 'post',
        data
      })
    },
    // 注册
    register: data => {
      return fetch({
        url: 'register',
        method: 'post',
        data
      })
    },
    // 获取手机验证码
    sendCaptcha: data => {
      return fetch({
        url: 'send-captcha',
        method: 'post',
        data
      })
    },
    // 找回密码
    resetPwd: data => {
      return fetch({
        url: 'users/reset-password-new',
        method: 'put',
        data
      })
    },
    // 获取报名信息
    getCompetitionRegistration: params => {
      return fetch({
        url: 'inquiry/competition-registration',
        method: 'get',
        params
      })
    },
    // 删除单个参评单位
    deleteOneRegistration: id => {
      return fetch({
        url: `competition-registration/${id}`,
        method: 'delete'
      })
    },
    // 提交报名信息
    postCompetitionRegistration: data => {
      return fetch({
        url: 'competition-registration/save',
        method: 'post',
        data
      })
    },
    // 保存参赛作品信息
    saveCompetitionProject: data => {
      return fetch({
        url: 'competition-projects/save',
        method: 'post',
        data
      })
    },
    // 获取参赛作品列表
    getProjects: params => {
      return fetch({
        url: 'inquiry/competition-projects',
        method: 'get',
        params
      })
    },
    // 获取参赛作品列表
    getProjectsPublished: params => {
      return fetch({
        url: 'inquiry/competition-projects-publish',
        method: 'get',
        params
      })
    },
    // 获取参赛作品数量
    getProjectCount: params => {
      return fetch({
        url: 'inquiry/competition-projects/count',
        method: 'get',
        params
      })
    },
    // 删除单个参赛作品
    deleteOneProject: id => {
      return fetch({
        url: `competition-projects/${id}`,
        method: 'delete'
      })
    },
    // 获取项目评分列表
    getProjectResult: params => {
      return fetch({
        url: 'inquiry/competition-projects',
        method: 'get',
        params
      })
    },
  }