<template>
  <div class="news">
    <div class="bannerWrap">
      <img class="banner" src="/img/banner.jpg" alt="">
    </div>
    <div class="section" id="detail">
      <Row>
        <Col :lg="18" :xs="24" class="leftInfo">
          <div class="info">
            <p class="mobile title center">{{info.title}}</p>
            <p class="center mobile date">{{info.startDate}}</p>
            <div class="timeWrap pc">
              <p class="day">{{getFormatDate(info.startDate).day}}</p>
              <p>{{getFormatDate(info.startDate).month}}</p>
              <p>{{getFormatDate(info.startDate).year}}</p>
            </div>
            <div class="height pc">
              <p class="name">{{info.title}}</p>
              <p>新闻类型：{{info.type}}</p>  
              <!-- <p>发布者：{{info.author}}&nbsp;&nbsp;&nbsp;&nbsp;浏览次数：{{info.views}}</p>   -->
            </div>
            <div>
              <img :src="getImageUrl(info.cover)" alt="" class="cover">
              <p class="ql-editor" v-html="info.content"></p>  
            </div>
          </div>
          <div class="info relationInfo">
            <div class="timeWrap pc">
              <p class="day">{{getFormatDate(info.startDate).day}}</p>
              <p>{{getFormatDate(info.startDate).month}}</p>
              <p>{{getFormatDate(info.startDate).year}}</p>
            </div>
            <Row :gutter="20">
              <Col span="12" class="leftInfo pc">
                <p class="name">{{leftInfo.title}}</p>
                <p class="threeRows" v-html="getHtmlText(leftInfo.content)"></p>
                <a v-if="leftInfo.type=='媒体报道'" class="colorGreen" :href="leftInfo.url" target="_blank" rel="noopener noreferrer">详细信息 ></a>
                <router-link v-else class="colorGreen" :to="`/news/${leftInfo.id}`">详细信息 ></router-link>
              </Col>
              <Col :lg="12" :xs="24">
                <p class="name">相关新闻</p>
                <p class="oneRow pc" v-for="l in list" :key="l.id">
                <a v-if="l.type=='媒体报道'" class="relationName" :href="l.url" target="_blank" rel="noopener noreferrer">{{getShortDate(l.startDate)}} {{l.title}}</a>
                <router-link v-else class="relationName" :to="`/news/${l.id}`">{{getShortDate(l.startDate)}} {{l.title}}</router-link>
                </p>
                <div class="mobile" v-for="l in list" :key="l.title">
                  <p>{{l.startDate}}</p>
                  <p class="mobileRelationName">
                    <a v-if="l.type=='媒体报道'" class="relationName" :href="l.url" target="_blank" rel="noopener noreferrer">{{l.title}}</a>
                    <router-link class="relationName" :to="`/news/${l.id}`">{{l.title}}</router-link>
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col span="6" class="pc">
          <img class="time" src="/img/introduction/time.png" alt="">
          <div id="contact" class="contact">
            <p class="title">联系我们</p> 
            <div class="clearfix">
              <div class="label">评审组委会：</div>
              <div class="info">010-88083947/2319</div>  
            </div>
            <div class="clearfix">
              <div class="label">组委会邮箱：</div>
              <div class="info">cbmea2006@126.com</div>  
            </div>
            <div class="clearfix">
              <div class="label">组委会地址：</div>
              <div class="info">北京市海淀区三里河路甲11号 中国建材大厦C座3层</div>  
            </div>
            <div class="clearfix">
              <div class="label">联&nbsp;&nbsp;&nbsp;系&nbsp;&nbsp;&nbsp;人：</div>
              <div class="info">13901127933(刘秀明)</div>  
            </div>
            <div class="clearfix">
              <div class="label">微信公众号：</div>
              <div class="info">国家建筑应用创新大奖</div>  
            </div>
            <p class="center"><img class="wxCode" src="/img/wxCode.jpg" alt=""></p>
          </div>
        </Col>
      </Row>
    </div>
  </div>
</template>

<script>
import NewsService from "@/api/news";
import "@/assets/quill.snow.css";

export default {
  data(){
    return{
      info: {},
      leftInfo: {},
      list: [],
    }
  },
  mounted(){
    let id = this.$route.params.id
    this.getInfo(id)
    this.getList()
  },
  methods:{
    // 拆分日期
    getFormatDate(date){
      let monthList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
      let temp = new Date(date)
      let year = temp.getFullYear(), month = monthList[temp.getMonth()], day = temp.getDate()
      return {year, month, day}
    },
    // 获取年月日
    getShortDate(date){
      return date.split('T')[0]
    },
    // 获取新闻详情
    getInfo(id) {
      NewsService.getNewsInfo(id)
        .then(res => {
          if (res.errorNo == 0) {
            this.info = res.data
          } else {
            this.$Message.error(res.errorMessage);
          }
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
        });
    },
    // 获取列表
    getList() {
      let condition = {
        page: 0,
        size: 3,
        'isHide.eq': 0,
        sort: 'isTop,desc'
      };
      NewsService.getNews(condition)
        .then(res => {
          if (res.errorNo == 0) {
            this.list = res.data;
            this.leftInfo = res.data[0]
          } else {
            this.$Message.error(res.errorMessage);
          }
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
        });
    }
  },
  watch: {
    '$route': function(from, to){
      if(to.name=='NewsDetail'){
        let id = this.$route.params.id
        this.getInfo(id)
        this.getList()
        document.getElementById('detail').scrollIntoView()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.news{
  .section{
    width: 80%;
    margin: auto;
    color: #8C8C8C;
    line-height: 1.8em;
    .leftInfo{
      border-right: 1px solid #E9E9E9;
      .info{
        position: relative;
        padding: 50px 10px 50px 110px;
        .timeWrap{
            width: 90px;
            height: 90px;
            position: absolute;
            left: 0;
            top: 50px;
            color: #4C5C4E;
            background: #E6EBE8;
            vertical-align: bottom;
            line-height: 1.3em;
            padding: 22px 10px 8px;
          .day{
            font-size: 24px;
            color: #287743;
            padding-bottom: 5px;
          }
        }
        .height{
          height: 90px;
          margin-bottom: 20px;
        }
        .cover{
          width: 100%;
        }
        .name{
          font-size: 16px;
          color: #4C5C4E;
          padding-bottom: 5px;
        }
      }
    }
    .relationInfo{
      border-top: 1px solid #E9E9E9;
      .relationName{
        color: #8C8C8C;
        &:hover{
          color: #287743;
        }
      }
    }
    .time{
      width: 100%;
      padding-top: 50px;
    }
    .contact{
      padding: 20px;
      line-height: 1.8em;
      .title{
        font-size: 18px;
        padding-bottom: 10px;
      }
    }
  }
  @media screen and (max-width: 992px) {
    .section{
      width: 100%;
      .leftInfo{
        border-right: none;
        .info{
          padding-bottom: 0;
          padding-left: 10px;
          .title{
            font-size: 24px;
            padding-bottom: 20px;
            font-weight: bold;
            color: #4D4D4D;
            line-height: 1.5em;
          }
          .date{
            padding-bottom: 10px;
          }
          .name{
            font-size: 24px;
            padding-bottom: 15px;
          }
          .mobileRelationName{
            border-bottom: 1px solid #E9E9E9;
            margin-bottom: 10px;
            padding-bottom: 10px;
          }
        }
      }
    }
  }
}
</style>