import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'

import ViewUI from 'view-design'
import 'view-design/dist/styles/iview.css'

Vue.config.productionTip = false
Vue.use(ViewUI)

import mixins from './utils/mixins'
Vue.mixin(mixins);

import 'fullpage.js/vendors/scrolloverflow';
import VueFullPage from 'vue-fullpage.js';
Vue.use(VueFullPage);

import './assets/common.scss'

router.afterEach(() => {
  let width = document.documentElement.clientWidth
  if(width<992){
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')