<template>
  <div class="publish">
    <div class="bannerWrap">
      <img class="banner" src="/img/banner.jpg" alt="">
    </div>
    <div class="section">
      <Row>
        <Col :lg="18" :xs="24" class="leftInfo">
          <div class="info">
            <p class="mobile title center">表彰项目公布</p>
            <div class="formBox">
              <Form :model="form" label-position="right" :label-width="80">
                <Row :gutter="20">
                  <Col span="12">
                    <FormItem label="项目名称">
                      <Input v-model="form.name" clearable @on-enter="search"></Input>
                    </FormItem>
                  </Col>
                  <Col span="12">
                    <FormItem label="参评单位">
                      <Input v-model="form.regName" clearable @on-enter="search"></Input>
                    </FormItem>
                  </Col>
                  <Col span="12">
                    <FormItem label="联合申报">
                      <Input v-model="form.jointCompanyList" clearable @on-enter="search"></Input>
                    </FormItem>
                  </Col>
                  <Col span="12" >
                    <Button class="defaultBtn noMg" type="primary" @click="search">查询</Button>
                  </Col>
                </Row>
              </Form>  
            </div>
            <Table stripe :columns="columns" :data="list" :loading="loading"></Table>
            <div class="pageWrap">
              <Page :total="total" :page-size="30" :current="currentPage" show-total @on-change="changePage"></Page>
            </div>
          </div>
        </Col>
        <Col span="6" class="pc">
          <img class="time" src="/img/introduction/time.png" alt="">
          <div id="contact" class="contact">
            <p class="title">联系我们</p> 
            <div class="clearfix">
              <div class="label">评审组委会：</div>
              <div class="info">010-88083947/2319</div>  
            </div>
            <div class="clearfix">
              <div class="label">组委会邮箱：</div>
              <div class="info">cbmea2006@126.com</div>  
            </div>
            <div class="clearfix">
              <div class="label">组委会地址：</div>
              <div class="info">北京市海淀区三里河路甲11号 中国建材大厦C座3层</div>  
            </div>
            <div class="clearfix">
              <div class="label">联&nbsp;&nbsp;&nbsp;系&nbsp;&nbsp;&nbsp;人：</div>
              <div class="info">13901127933(刘秀明)</div>  
            </div>
            <div class="clearfix">
              <div class="label">微信公众号：</div>
              <div class="info">国家建筑应用创新大奖</div>  
            </div>
            <p class="center"><img class="wxCode" src="/img/wxCode.jpg" alt=""></p>
          </div>
        </Col>
      </Row>
    </div>
  </div>
</template>

<script>
import userService from "@/api/user";
export default {
  data(){
    return{
      loading: false,
      list: [],
      form: {},
      total: 0,
      currentPage: 1,
      columns: [
        {
          title: '序号',
          key: 'id'
        },
        {
          title: '项目名称',
          key: 'name'
        },
        {
          title: '参评类别',
          render: (h, params) => {
            let text = params.row.type;
            if (params.row.subType) {
              text += " - " + params.row.subType;
            }
            return h("div", text);
          }
        },
        {
          title: '参评单位',
          render: (h, params) => {
            let text = params.row.regInfo.name;
            return h("div", text);
          }
        },
        {
          title: '联合申报单位',
          key: 'jointCompanyList'
        }
      ]
    }
  },
  mounted(){
    this.getList()
  },
  methods:{
    // 查询
    search() {
      this.currentPage = 1;
      this.getList();
    },
    // 获取列表
    getList() {
      this.loading = true;
      let condition = {
        page: this.currentPage - 1,
        size: 30,
        sort: "finalScore,desc",
        "firstStatus.eq": "FINAL",
        "finalStatus.eq": "APPROVED",
        "stage.eq": "FINAL",
        "checkStatus.eq": "SUCCESS",
        "status.eq": "PUBLISHED",
        "name.contains": this.form.name || null,
        "regName.contains": this.form.regName || null,
        "jointCompanyList.contains": this.form.jointCompanyList || null,
      };
      this.selection = [];
      userService.getProjectsPublished(condition)
        .then(res => {
          if (res.errorNo == 0) {
            this.currentPage == 1 && (this.total = res.totalSize);
            this.list = res.data;
          } else {
            this.$Message.error(res.errorMessage);
          }
          this.loading = false;
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
          this.loading = false;
        });
    },
    // 换页
    changePage(p) {
      this.currentPage = p;
      this.getList();
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },
  }
}
</script>

<style lang="scss" scoped>
.publish{
  .section{
    width: 80%;
    margin: auto;
    color: #8C8C8C;
    line-height: 1.8em;
    .leftInfo{
      border-right: 1px solid #E9E9E9;
      .info{
        padding: 50px 15px;
        
      }
    }
    .time{
      width: 100%;
      padding-top: 50px;
    }
    .contact{
      padding: 20px;
      line-height: 1.8em;
      .title{
        font-size: 18px;
        padding-bottom: 10px;
      }
    }
  }
  @media screen and (max-width: 992px) {
    .section{
      width: 100%;
      .leftInfo{
        border-right: none;
        .info{
          padding-bottom: 0;
          .title{
            font-size: 24px;
            padding-bottom: 20px;
            font-weight: bold;
            color: #4D4D4D;
          }
        }
      }
    }
  }
}
</style>